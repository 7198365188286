import React, { useEffect } from 'react'
import { OpenApi } from '@newageerp/nae-react-auth-wrapper';
import { NaeSSchemaMap } from '../config/NaeSSchema';
import { functions, UI } from '@newageerp/nae-react-ui';
import { WidgetProps } from '@newageerp/nae-react-ui/dist/UI/Widget/NEWidgets';

interface IData {
    id: number,
    name: string,
}

const defElement: IData = {
    id: 0,
    name: '',
}

interface IElement {
    id: number,
    categories: IData[],
}
const defIElement: IElement = {
    id: 0,
    categories: [
        {
            id: 0,
            name: '',
        }
    ]
}

export default function CategoryWidget(props: WidgetProps) {
    const { element, schema } = props;

    const [getData, getDataParams] = OpenApi.useUList<IData>(NaeSSchemaMap.AppContentCategory.schema, functions.properties.getKeysFromObject(defElement));

    const [getElement, getElementParams] = OpenApi.useUList<IElement>(schema ? schema : '', functions.properties.getKeysFromObject(defIElement));

    const [addCategory, addCategoryParams] = OpenApi.useURequest('CategoryAddToElement');
    const [remCategory, remCategoryParams] = OpenApi.useURequest('CategoryRemoveElement');

    const getElementData = () => {
        getElement([{ classicMode: true, and: [["i.id", "=", element.id]] }], 1, 1)
    }

    useEffect(() => {
        getData([], 1, 1000, [{ key: 'i.name', value: 'ASC' }]);
        getElementData();
    }, []);

    const categories = getDataParams.data.data;
    const elementCategories = (getElementParams.data.data.length > 0 ? getElementParams.data.data[0].categories : []).map(f => f.id);

    const doAdd = (id: number) => {
        addCategory({
            schema: schema ? schema : '',
            elementId: element.id,
            categoryId: id
        }).then(getElementData)
    }
    const doRemove = (id: number) => {
        remCategory({
            schema: schema ? schema : '',
            elementId: element.id,
            categoryId: id
        }).then(getElementData)
    }

    if (getDataParams.loading || getElementParams.loading) {
        return <UI.Loader.Logo />
    }

    return (
        <UI.Card.WhiteCard>
            <div className={"flex flex-wrap gap-2"}>
                {categories.map(cat => {
                    const isActive = elementCategories.indexOf(cat.id) >= 0;

                    if (isActive) {
                        return (
                            <UI.Buttons.Button onClick={() => doRemove(cat.id)} bgColor={UI.Buttons.ButtonBgColor.green} size={UI.Buttons.ButtonSize.sm}>{cat.name}</UI.Buttons.Button>
                        )
                    }

                    return (
                        <UI.Buttons.Button onClick={() => doAdd(cat.id)} size={UI.Buttons.ButtonSize.sm}>{cat.name}</UI.Buttons.Button>
                    )
                })}
            </div>
        </UI.Card.WhiteCard>
    )
}
