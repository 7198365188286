import { UI } from '@newageerp/nae-react-ui';
import { INaeWidget } from '@newageerp/nae-react-ui/dist/interfaces';
import { NaeSSchemaMap } from '../../NaeSSchema';
import CategoryWidget from '../../../UserComponents/CategoryWidget';
import TopicWidget from '../../../UserComponents/TopicWidget';
import CategoryPrayWidget from '../../../UserComponents/CategoryPrayWidget';
export const AppContentPrayWidgets: INaeWidget[] = [
    {
        schema: NaeSSchemaMap.AppContentPray.schema,
        type: UI.Widget.WidgetType.viewRight,
        sort: 0,
        options: {
            
        },
        comp: CategoryWidget
    },
    {
        schema: NaeSSchemaMap.AppContentPray.schema,
        type: UI.Widget.WidgetType.viewRight,
        sort: 10,
        options: {
            
        },
        comp: TopicWidget
    },
    {
        schema: NaeSSchemaMap.AppContentPray.schema,
        type: UI.Widget.WidgetType.viewRight,
        sort: 20,
        options: {
            
        },
        comp: CategoryPrayWidget
    },
];
