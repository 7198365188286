import { INaeEditSettings } from '@newageerp/nae-react-ui/dist/interfaces';
import { schemaMap } from '../../schema/schema';
import { propertiesKeys } from '../../properties/propertiesKeys';
export const AppContentVerseEditFields: INaeEditSettings[] = [
    {
        type: "main",
        schema: schemaMap.AppContentVerse.schema,
        fields: [
            [{ key: propertiesKeys["app-content-verse"].reference }],
            [{ key: propertiesKeys["app-content-verse"].url }],
            [{ key: propertiesKeys["app-content-verse"].text }]    
        ],
      },
];
