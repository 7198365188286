
import React, { Fragment } from 'react'
import { PropsId, PropsLink } from './types';
import { Hooks, functions, UI } from "@newageerp/nae-react-ui";
import { NaeSSchemaMap } from '../../config/NaeSSchema';
import moment from "moment";
import { SelectorAppContentArticleNae, SelectorAppContentTopicNae, SelectorAppUserDeviceNae, SelectorAppUserNae } from '../../Components/Models/ormSelectors';
import { useAppContentArticleHookNae } from '../../Components/Hooks/useAppContentArticleHookNae';
import { useAppContentTopicHookNae } from '../../Components/Hooks/useAppContentTopicHookNae';
import { useAppUserDeviceHookNae } from '../../Components/Hooks/useAppUserDeviceHookNae';
import { useAppUserHookNae } from '../../Components/Hooks/useAppUserHookNae';

  
export const getHookForSchema = (schema: string) => {
  let selector : any;
  if (schema === 'app-content-article') {
    return useAppContentArticleHookNae;
  }
  if (schema === 'app-content-topic') {
    return useAppContentTopicHookNae;
  }
  if (schema === 'app-user-device') {
    return useAppUserDeviceHookNae;
  }
  if (schema === 'app-user') {
    return useAppUserHookNae;
  }
  return selector;
}
  
export function AppContentTopicNameFieldNae(props: PropsLink) {
  const element = useAppContentTopicHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  const fieldProperty = functions.properties.getPropertyForPath(`${NaeSSchemaMap.AppContentTopic.schema}.name`);
  if (!fieldProperty) {
    return <Fragment/>
  }

  if (props.link) {
    return (
      <UI.Buttons.SchemaMultiLink
          id={props.id}
          schema={NaeSSchemaMap.AppContentTopic.schema}
          className={"text-left"}
          buttonsNl={!props.linkInline}
          onClickDef="popup"
      >
        <UI.Content.ViewContentValue element={element} property={fieldProperty}/>
      </UI.Buttons.SchemaMultiLink>
    )
  }

  return (
      <Fragment>
        <UI.Content.ViewContentValue element={element} property={fieldProperty}/>
      </Fragment>
  )
}

export function AppContentTopicNameFieldNaeContext() {
  const parentElement = Hooks.useBuilderWidget().element;
  
  const element = useAppContentTopicHookNae(!!parentElement && parentElement.id ? parentElement.id : -1);
  if (!element) {
      return <Fragment />
  }

  return (
      <Fragment>
          {element.name}
      </Fragment>
  )
}

