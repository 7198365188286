import { INaeTab } from '@newageerp/nae-react-ui/dist/interfaces';
import { propertiesKeys } from '../../properties/propertiesKeys';
import { schemaMap } from '../../schema/schema';
export const AppContentBibleTestamentTabs: INaeTab[] = [
    {
        quickSearchFilterKeys: ['i.title'],
        fields: [
          { key: propertiesKeys["app-content-bible-testament"].title, link: true },
        ],
        sort: [
          {
            key: "i.title",
            value: "ASC",
          },
        ],
        schema: schemaMap.AppContentBibleTestament.schema,
        type: "main",
      },
];
