import { INaeViewSettings } from "@newageerp/nae-react-ui/dist/interfaces";
import { schemaMap } from "../../schema/schema";
import { propertiesKeys } from "../../properties/propertiesKeys";
export const AppContentSoundViewFields: INaeViewSettings[] = [
  {
    type: "main",
    schema: schemaMap.AppContentSound.schema,
    fields: [
      [{ key: propertiesKeys["app-content-sound"].title }],
      [{ key: propertiesKeys["app-content-sound"].subTitle }],
      [{ key: propertiesKeys["app-content-sound"].duration }],
      [{ key: propertiesKeys["app-content-sound"].url }],
      [{ key: propertiesKeys["app-content-sound"].audio }],
    ],
  },
];
