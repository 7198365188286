import { functions, UI } from '@newageerp/nae-react-ui';
import React, { Fragment, useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { SelectorAppContentArticleNae, SelectorAppContentTopicNae, SelectorAppUserDeviceNae, SelectorAppUserNae } from "../Models/ormSelectors" 
import { NaeSSchemaMap } from '../../config/NaeSSchema';


// ADD IMPORT FIELDS START
    import { IAppContentArticleFieldsNae } from './useAppContentArticleHookNae';
    import { IAppContentTopicFieldsNae } from './useAppContentTopicHookNae';
    import { IAppUserDeviceFieldsNae } from './useAppUserDeviceHookNae';
    import { IAppUserFieldsNae } from './useAppUserHookNae';
    // ADD IMPORT FIELDS FINISH
import { useRecoilValue } from 'recoil';
import { OpenApi } from '@newageerp/nae-react-auth-wrapper';
import { useDataCache } from './DataCacheProvider';

export default function DataCacheSocketComponent() {
  const userState: any = useRecoilValue(OpenApi.naeUserState);

  const { addToQueue } = useDataCache();
  const [socketItem, setSocketItem] = useState();

const AppUserDeviceDataNae = useSelector(state => SelectorAppUserDeviceNae(state));
const AppUserDataNae = useSelector(state => SelectorAppUserNae(state));
const AppContentTopicDataNae = useSelector(state => SelectorAppContentTopicNae(state));
const AppContentArticleDataNae = useSelector(state => SelectorAppContentArticleNae(state));
// ADD SELECTOR HERE

  // console.log('onSocketData', TerminalData.length, UnloadAddressData.length);

  const onSocketDoRequest = (data: any) => {
    let fields = ['id'];
    let dataToCheck : any = [];
    let schema = functions.schemas.getSchemaByClassName(data.schema);

    // SCHEMA CHECK START
if (data.schema === NaeSSchemaMap.AppContentArticle.className) {
  dataToCheck = AppContentArticleDataNae;
  fields = IAppContentArticleFieldsNae;
}
if (data.schema === NaeSSchemaMap.AppContentTopic.className) {
  dataToCheck = AppContentTopicDataNae;
  fields = IAppContentTopicFieldsNae;
}
if (data.schema === NaeSSchemaMap.AppUserDevice.className) {
  dataToCheck = AppUserDeviceDataNae;
  fields = IAppUserDeviceFieldsNae;
}
if (data.schema === NaeSSchemaMap.AppUser.className) {
  dataToCheck = AppUserDataNae;
  fields = IAppUserFieldsNae;
}
// SCHEMA CHECK FINISH

    dataToCheck = dataToCheck.map((el: any) => el.id);

    if (dataToCheck.indexOf(data.id) >= 0) {
      const item = {
        elementId: data.id,
        schema: schema,
        fields: fields,
      }
      addToQueue(item);
    }
  }



  const onForceSocketDoRequest = (data: any) => {
    // if (data.schema === NaeSSchemaMap.Note.className) {
    //   const item = {
    //     elementId: data.id,
    //     schema: NaeSSchemaMap.Note.schema,
    //     fields: INoteFieldsNae,
    //   }
    //   addToQueue(item);
    // }
  }

  useEffect(() => {
    if (socketItem) {
      onSocketDoRequest(socketItem);
    }
  }, [socketItem]);

  useEffect(() => {
    UI.Socket.Service.addCallback(
      "data-update-all",
      "data-update-all",
      setSocketItem
    );
    return () => {
      UI.Socket.Service.removeCallback(
        "data-update-all",
        "data-update-all"
      );
    };
  }, []);


  useEffect(() => {
    UI.Socket.Service.subscribe(`notes-${userState.id}`);
    UI.Socket.Service.subscribe(`notes-${userState.permissionGroup}`);

    UI.Socket.Service.addCallback(
      `notes-create`,
      `notes-create`,
      onForceSocketDoRequest
    );
    return () => {
      UI.Socket.Service.unsubscribe(`notes-${userState.id}`);
      UI.Socket.Service.unsubscribe(`notes-${userState.permissionGroup}`);

      UI.Socket.Service.removeCallback(
        `notes-create`,
        `notes-create`,
      );
    };
  }, [userState]);

  return (
    <Fragment />
  )
}
