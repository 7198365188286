import { INaeViewSettings } from "@newageerp/nae-react-ui/dist/interfaces";
import { propertiesKeys } from "../../properties/propertiesKeys";
import { schemaMap } from "../../schema/schema";
export const AppContentQuoteViewFields: INaeViewSettings[] = [
  {
    type: "main",
    schema: schemaMap.AppContentQuote.schema,
    fields: [[{ key: propertiesKeys["app-content-quote"].url }]],
  },
];
