import { UI } from "@newageerp/nae-react-ui";
import React, { useContext, useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { OpenApi } from '@newageerp/nae-react-auth-wrapper';
import { useRecoilValue } from 'recoil';
import { SystemQueueSelector } from "../Models/ormSelectors";

export interface DataCacheProviderValue {
    queue: QueueItem[],
    addToQueue: (item: QueueItem) => void,
}

export const DataCacheContext = React.createContext<DataCacheProviderValue>({
    queue: [],
    addToQueue: (item: QueueItem) => { console.log('addToQueue', item) },
});

export const useDataCache = () => useContext(DataCacheContext);

export interface DataCacheProps {
    children: any;
}

interface QueueItem {
    id?: string,
    schema: string,
    elementId: number,
    fields: string[]
}

const cacheData = (userState: any) => ([
    
]);

export const DataCacheProvider = (props: DataCacheProps) => {
    const userState: any = useRecoilValue(OpenApi.naeUserState);

    const { t } = useTranslation();

    const dispatch = useDispatch();

    // const [queue, setQueue] = useState<QueueItem[]>([]);
    const queue = useSelector(state => SystemQueueSelector(state));
    const [isLoading, setIsLoading] = useState(false);
    const [isQueueLoading, setIsQueueLoading] = useState(false);

    const [isCaching, setIsCaching] = useState(true);
    const [preloadCache] = OpenApi.useURequest('NAEUMultipleList');
    const isLoggedIn = !!userState && userState.id > 0;
    useEffect(() => {
        if (!userState || !userState.id) {
            return;
        }
        preloadCache({
            data: cacheData(userState)
        }).then((res: any) => {
            const keys = Object.keys(res.data);
            keys.forEach(k => {
                dispatch({
                    type: "UPSERT_" + k,
                    payload: res.data[k].data
                })
            })
            setIsCaching(false);
        })
    }, [userState]);

    const loadElements = (items: QueueItem[]) => {
        setIsLoading(true)
        // console.log('loadElements', items.length);
        let loadCacheData: any = {};
        items.forEach((item) => {
            if (!(item.schema in loadCacheData)) {
                loadCacheData[item.schema] = {
                    schema: item.schema,
                    ids: [],
                    fieldsToReturn: item.fields
                };
            }
            loadCacheData[item.schema].ids.push(item.elementId);
        })
        const requestCacheData: any[] = [];
        Object.keys(loadCacheData).forEach(k => {
            const _data = loadCacheData[k];

            requestCacheData.push(
                {
                    schema: _data.schema,
                    page: 1,
                    pageSize: _data.ids.length,
                    fieldsToReturn: _data.fieldsToReturn,
                    filters: [{ and: [["i.id", "in", _data.ids, true]] }]
                }
            );
        })
        // console.log('loadElements', requestCacheData);
        preloadCache({
            data: requestCacheData
        }).then((res: any) => {
            const keys = Object.keys(res.data);
            keys.forEach(k => {
                dispatch({
                    type: "UPSERT_" + k,
                    payload: res.data[k].data
                })
            })
            setIsLoading(false);
        })
    }

    // const loadElement = (item: QueueItem) => {
    //     console.log('loadElement', item);
    //     // @ts-ignore
    //     const token: string = window.localStorage.getItem('token') ? window.localStorage.getItem('token') : "";
    //     const requestOptions = {
    //         method: "POST",
    //         headers: {
    //             "Content-Type": "application/json",
    //             "Authorization": token,
    //         },
    //         body: JSON.stringify({
    //             page: 1,
    //             pageSize: 1,
    //             fieldsToReturn: item.fields,
    //             filters: [{ classicMode: true, and: [["i.id", "=", item.elementId]] }]
    //         }),
    //     }
    //     setIsLoading(true)
    //     fetch('/app/nae-core/u/get/' + item.schema, requestOptions).
    //         then(res => res.json()).
    //         then((res) => {
    //             // add(res.data.data[0]);
    //             dispatch({
    //                 type: "UPSERT_" + item.schema,
    //                 payload: res.data
    //             })
    //             setIsLoading(false)
    //         }).catch(e => setIsLoading(false));
    // }

    const addToQueue = (item: QueueItem) => {
        // add(item);
        item.id = item.schema + "-" + item.elementId;
        dispatch({
            type: "UPSERT_QUEUE",
            payload: item
        })
    }

    useEffect(() => {
        // console.log('queue', queue);
        if (!isLoading && !isQueueLoading) {
            if (queue.length > 0) {
                loadElements(queue);
                dispatch(
                    {
                        type: "REMOVE_QUEUE",
                        payload: queue
                    }
                )
            }
        }
    }, [isLoading, isQueueLoading, queue]);

    return (
        <DataCacheContext.Provider
            value={{
                queue,
                addToQueue,
            }}
        >
            {isCaching && isLoggedIn ?

                <div className={"w-96 mx-auto mt-8 flex justify-center flex-wrap gap-8"}>
                    <UI.Typography.H3 className="w-full text-center">{t('Kraunami duomenys...')}</UI.Typography.H3>
                    <UI.Loader.Logo />
                </div>

                : props.children}
        </DataCacheContext.Provider>
    );
};
