import { INaeTab } from "@newageerp/nae-react-ui/dist/interfaces";
import { NaeSSchemaMap } from "../../NaeSSchema";
export const UserAuthSignInLogTabs: INaeTab[] = [
  {
    quickSearchFilterKeys: [
        'i.email',
        'i.password',
        'i.ip'
    ],
    type: "main",
    schema: NaeSSchemaMap.UserAuthSignInLog.schema,
    fields: [
      {
        key: "email",
      },
      {
        key: "password",
      },
      {
        key: "success",
      },
      {
        key: "ip",
      },
      {
        key: "createdAt",
      },
    ],
    sort: [
        {
            key: 'i.id',
            value: 'DESC',
        }
    ],
    disableCreate: true,
  },
];
