import { createSelector } from "redux-orm";
import orm from "./orm";

export const SystemQueueSelector = createSelector(orm.QueueModel);

export const SelectorAppUserDeviceNae = createSelector(orm.AppUserDeviceModel);
export const SelectorAppUserNae = createSelector(orm.AppUserModel);
export const SelectorQueueNae = createSelector(orm.QueueModel);
export const SelectorAppContentTopicNae = createSelector(orm.AppContentTopicModel);
export const SelectorAppContentArticleNae = createSelector(orm.AppContentArticleModel);
