import { INaeEditSettings } from '@newageerp/nae-react-ui/dist/interfaces';
import { schemaMap } from '../../schema/schema';
import { propertiesKeys } from '../../properties/propertiesKeys';
import { NaeSPropertiesKeys } from '../../NaeSPropertiesKeys';
export const AppContentPrayEditFields: INaeEditSettings[] = [
    {
        type: "main",
        schema: schemaMap.AppContentPray.schema,
        fields: [
          [{ key: NaeSPropertiesKeys["app-content-pray"].reference }],
          [{ key: NaeSPropertiesKeys["app-content-pray"].text }],
          [{ key: NaeSPropertiesKeys["app-content-pray"].imageUrl }],
        ],
      },
];
