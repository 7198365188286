import { INaeViewSettings } from "@newageerp/nae-react-ui/dist/interfaces";
import { AbonddedCartEmailViewFields } from "./view/abondded-cart-email.view.fields";
import { AppContentViewFields } from "./view/app-content.view.fields";
import { AppContentBibleChapterViewFields } from "./view/app-content-bible-chapter.view.fields";
import { AppContentBibleTestamentViewFields } from "./view/app-content-bible-testament.view.fields";
import { AppContentBibleVerseViewFields } from "./view/app-content-bible-verse.view.fields";
import { AppContentCategoryViewFields } from "./view/app-content-category.view.fields";
import { AppContentPrayViewFields } from "./view/app-content-pray.view.fields";
import { AppContentQuoteViewFields } from "./view/app-content-quote.view.fields";
import { AppContentSoundViewFields } from "./view/app-content-sound.view.fields";
import { AppContentUserPrayerViewFields } from "./view/app-content-user-prayer.view.fields";
import { AppContentUserPrayerCommentViewFields } from "./view/app-content-user-prayer-comment.view.fields";
import { AppContentVerseViewFields } from "./view/app-content-verse.view.fields";
import { AppUserViewFields } from "./view/app-user.view.fields";
import { AppUserDeviceViewFields } from "./view/app-user-device.view.fields";
import { SubscriptionViewFields } from "./view/subscription.view.fields";
import { UserViewFields } from "./view/user.view.fields";
import { UserAuthSignInLogViewFields } from "./view/user-auth-sign-in-log.view.fields";

export const NaeViewFields: INaeViewSettings[] = [
  ...AbonddedCartEmailViewFields,
...AppContentViewFields,
...AppContentBibleChapterViewFields,
...AppContentBibleTestamentViewFields,
...AppContentBibleVerseViewFields,
...AppContentCategoryViewFields,
...AppContentPrayViewFields,
...AppContentQuoteViewFields,
...AppContentSoundViewFields,
...AppContentUserPrayerViewFields,
...AppContentUserPrayerCommentViewFields,
...AppContentVerseViewFields,
...AppUserViewFields,
...AppUserDeviceViewFields,
...SubscriptionViewFields,
...UserViewFields,
...UserAuthSignInLogViewFields,

];