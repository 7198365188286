import { INaeTab } from "@newageerp/nae-react-ui/dist/interfaces";
import { NaeSSchemaMap } from "../../NaeSSchema";
import { NaeSPropertiesKeys } from "../../NaeSPropertiesKeys";
export const AppContentCategoryTabs: INaeTab[] = [
  {
    schema: NaeSSchemaMap.AppContentCategory.schema,
    type: "main",
    sort: [
      {
        key: "i.sort",
        value: "ASC",
      },
      {
        key: "i.name",
        value: "ASC",
      },
    ],
    fields: [
      { key: NaeSPropertiesKeys["app-content-category"].name, link: true },
      { key: NaeSPropertiesKeys["app-content-category"].sort },
    ],
  },
];
