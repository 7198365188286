import React, { Fragment } from "react";
import { INaeTab } from "@newageerp/nae-react-ui/dist/interfaces";
import { AbonddedCartEmailTabs } from "./tabs/abondded-cart-email.tabs";
import { AppContentTabs } from "./tabs/app-content.tabs";
import { AppContentBibleChapterTabs } from "./tabs/app-content-bible-chapter.tabs";
import { AppContentBibleTestamentTabs } from "./tabs/app-content-bible-testament.tabs";
import { AppContentBibleVerseTabs } from "./tabs/app-content-bible-verse.tabs";
import { AppContentCategoryTabs } from "./tabs/app-content-category.tabs";
import { AppContentPrayTabs } from "./tabs/app-content-pray.tabs";
import { AppContentQuoteTabs } from "./tabs/app-content-quote.tabs";
import { AppContentSoundTabs } from "./tabs/app-content-sound.tabs";
import { AppContentUserPrayerTabs } from "./tabs/app-content-user-prayer.tabs";
import { AppContentUserPrayerCommentTabs } from "./tabs/app-content-user-prayer-comment.tabs";
import { AppContentVerseTabs } from "./tabs/app-content-verse.tabs";
import { AppUserTabs } from "./tabs/app-user.tabs";
import { AppUserDeviceTabs } from "./tabs/app-user-device.tabs";
import { SubscriptionTabs } from "./tabs/subscription.tabs";
import { UserTabs } from "./tabs/user.tabs";
import { UserAuthSignInLogTabs } from "./tabs/user-auth-sign-in-log.tabs";

export const NaeTabs: INaeTab[] = [
...AbonddedCartEmailTabs,
...AppContentTabs,
...AppContentBibleChapterTabs,
...AppContentBibleTestamentTabs,
...AppContentBibleVerseTabs,
...AppContentCategoryTabs,
...AppContentPrayTabs,
...AppContentQuoteTabs,
...AppContentSoundTabs,
...AppContentUserPrayerTabs,
...AppContentUserPrayerCommentTabs,
...AppContentVerseTabs,
...AppUserTabs,
...AppUserDeviceTabs,
...SubscriptionTabs,
...UserTabs,
...UserAuthSignInLogTabs,

];
      