import { INaeTab } from '@newageerp/nae-react-ui/dist/interfaces';
import { propertiesKeys } from '../../properties/propertiesKeys';
import { schemaMap } from '../../schema/schema';
export const AppContentBibleChapterTabs: INaeTab[] = [
    {
        quickSearchFilterKeys: ['i.number'],
        fields: [
          { key: propertiesKeys["app-content-bible-chapter"].title, link: true },
          { key: propertiesKeys["app-content-bible-chapter"].number},
          { key: propertiesKeys["app-content-bible-chapter"].versesCount},
          { key: propertiesKeys["app-content-bible-chapter"].testament, relName: 'title' },
        ],
        sort: [
          {
            key: "i.number",
            value: "ASC",
          },
        ],
        schema: schemaMap.AppContentBibleChapter.schema,
        type: "main",
      },
];
