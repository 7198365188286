import { useEffect } from "react";
import { NaeSSchemaMap } from "../../config/NaeSSchema";
import { useDataCache } from "./DataCacheProvider";
import { useSelector } from "react-redux";
import { SelectorAppUserNae } from "../Models/ormSelectors";

interface ChildId {
  id: number
}

export interface IAppUserModelNae {
    id: number,

}

export const IAppUserFieldsNae = ["id"];

export function useAppUserHookNae(id: number) : IAppUserModelNae {
  // @ts-ignore
  const element = useSelector((state) => SelectorAppUserNae(state, id));

  const { addToQueue } = useDataCache();

  useEffect(() => {
    if (!element) {
      addToQueue({
        elementId: id,
        schema: NaeSSchemaMap.AppUser.schema,
        fields: IAppUserFieldsNae,
      });
    }
  }, [element, id]);

  return element;
}
