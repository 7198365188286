import { INaeTab } from "@newageerp/nae-react-ui/dist/interfaces";
import { NaeSPropertiesKeys } from "../../NaeSPropertiesKeys";
import { NaeSSchemaMap } from "../../NaeSSchema";
export const AppContentUserPrayerCommentTabs: INaeTab[] = [
  {
    fields: [
      {
        key: NaeSPropertiesKeys["app-content-user-prayer-comment"].comment,
        link: true,
      },
      {
        key:
          NaeSPropertiesKeys["app-content-user-prayer-comment"].appUser +
          ".name",
          titlePath: NaeSPropertiesKeys["app-content-user-prayer-comment"].appUser
      },
      {
        key:
          NaeSPropertiesKeys["app-content-user-prayer-comment"].userName,
      },
      {
        key:
          NaeSPropertiesKeys["app-content-user-prayer-comment"].createdAt,
      },
    ],
    sort: [
      {
        key: "i.id",
        value: "DESC",
      },
    ],
    schema: NaeSSchemaMap.AppContentUserPrayerComment.schema,
    type: "main",
  },
];
