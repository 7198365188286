import { UI } from '@newageerp/nae-react-ui';
import { INaeWidget } from '@newageerp/nae-react-ui/dist/interfaces';
import { NaeSSchemaMap } from '../../NaeSSchema';
import { WidgetProps } from '@newageerp/nae-react-ui/dist/UI/Widget/NEWidgets';
export const AppContentUserPrayerWidgets: INaeWidget[] = [
    {
        schema: NaeSSchemaMap.AppContentUserPrayer.schema,
        type: UI.Widget.WidgetType.viewRightButtons,
        comp: UI.Form.Widget.Create,
        options: {
            schema: NaeSSchemaMap.AppContentUserPrayerComment.schema,
        },
        sort: 10,
    },
    {
        schema: NaeSSchemaMap.AppContentUserPrayer.schema,
        type: UI.Widget.WidgetType.viewBottom,
        comp: UI.Content.Widget.List,
        options: {
          schema: NaeSSchemaMap.AppContentUserPrayerComment.schema,
          type: "main",
          filter: (props: WidgetProps) => {
            const { element } = props;
            return {
              and: [["i.prayer", "=", element.id]],
            };
          },
        },
        sort: 20,
      },
];
