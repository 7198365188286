import { INaeTab } from '@newageerp/nae-react-ui/dist/interfaces';
import { schemaMap } from '../../schema/schema';
import { propertiesKeys } from '../../properties/propertiesKeys';
export const UserTabs: INaeTab[] = [
    {
        schema: schemaMap.User.schema,
        type: "main",
        fields: [
            {
                key: propertiesKeys['user'].email,
                link: true,
            },
        ],
        sort: [
          {
            key: "i.id",
            value: "DESC",
          },
        ],
      },
];
