import { INaeTab } from "@newageerp/nae-react-ui/dist/interfaces";
import { propertiesKeys } from "../../properties/propertiesKeys";
import { schemaMap } from "../../schema/schema";
export const AppContentBibleVerseTabs: INaeTab[] = [
  {
    quickSearchFilterKeys: ["i.line", "i.chapter.title"],
    fields: [
      {
        key: propertiesKeys["app-content-bible-verse"].chapter,
        relName: "number",
      },
      { key: propertiesKeys["app-content-bible-verse"].number },
      { key: propertiesKeys["app-content-bible-verse"].lineNumber },
      { key: propertiesKeys["app-content-bible-verse"].line, link: true },
      {
        key: propertiesKeys["app-content-bible-verse"].chapter,
        relName: "title",
      },
    ],
    sort: [
      {
        key: "i.chapter.number",
        value: "ASC",
      },
      {
        key: "i.number",
        value: "ASC",
      },
      {
        key: "i.lineNumber",
        value: "ASC",
      },
    ],
    schema: schemaMap.AppContentBibleVerse.schema,
    type: "main",
  },
];
