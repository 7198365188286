import { INaeEditSettings } from '@newageerp/nae-react-ui/dist/interfaces';
import { schemaMap } from '../../schema/schema';
import { propertiesKeys } from '../../properties/propertiesKeys';
import { NaeSPropertiesKeys } from '../../NaeSPropertiesKeys';
export const AppContentUserPrayerEditFields: INaeEditSettings[] = [
    {
        schema: schemaMap.AppContentUserPrayer.schema,
        type: "main",
        fields: [
            [
                {
                    key: NaeSPropertiesKeys['app-content-user-prayer'].name
                }
            ],
            [
                {
                    key: NaeSPropertiesKeys['app-content-user-prayer'].prayer
                }
            ],
            [
                {
                    key: NaeSPropertiesKeys['app-content-user-prayer'].createdAt
                }
            ],
            [
                {
                    key: NaeSPropertiesKeys['app-content-user-prayer'].moderated
                }
            ],
            [
                {
                    key: NaeSPropertiesKeys['app-content-user-prayer'].praysCount
                }
            ],
            [
                {
                    key: NaeSPropertiesKeys['app-content-user-prayer'].commentsCount
                }
            ]
        ],
      },
];
