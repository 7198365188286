export const NaeSPropertiesKeys = {
    "abondded-cart-email": {
        "id": "id",
        "email": "email",
        "hasOrder": "hasOrder",
        "createdAt": "createdAt",
        "attrs": "attrs",
        "synced": "synced"
    },
    "app-content": {
        "id": "id",
        "tags": "tags",
        "longText": "longText",
        "content": "content"
    },
    "app-content-bible-chapter": {
        "id": "id",
        "testament": "testament",
        "number": "number",
        "versesCount": "versesCount",
        "title": "title"
    },
    "app-content-bible-testament": {
        "id": "id",
        "chapters": "chapters",
        "title": "title"
    },
    "app-content-bible-verse": {
        "id": "id",
        "chapter": "chapter",
        "line": "line",
        "lineNumber": "lineNumber",
        "number": "number"
    },
    "app-content-category": {
        "id": "id",
        "name": "name",
        "appContentQuotes": "appContentQuotes",
        "appContentVerses": "appContentVerses",
        "appContentSounds": "appContentSounds",
        "appContentPrays": "appContentPrays",
        "appContentArticles": "appContentArticles",
        "imageUrl": "imageUrl",
        "intro": "intro",
        "articlesCount": "articlesCount",
        "sort": "sort"
    },
    "app-content-pray": {
        "id": "id",
        "reference": "reference",
        "text": "text",
        "categories": "categories",
        "topics": "topics",
        "prayCategories": "prayCategories",
        "imageUrl": "imageUrl"
    },
    "app-content-quote": {
        "id": "id",
        "url": "url",
        "categories": "categories",
        "topics": "topics"
    },
    "app-content-sound": {
        "id": "id",
        "url": "url",
        "title": "title",
        "subTitle": "subTitle",
        "audio": "audio",
        "duration": "duration",
        "categories": "categories",
        "topics": "topics"
    },
    "app-content-user-prayer": {
        "id": "id",
        "createdAt": "createdAt",
        "name": "name",
        "appUser": "appUser",
        "prayer": "prayer",
        "moderated": "moderated",
        "location": "location",
        "praysCount": "praysCount",
        "commentsCount": "commentsCount",
        "comments": "comments",
        "prays": "prays",
        "imageUrl5": "imageUrl5",
        "imageUrl4": "imageUrl4",
        "imageUrl3": "imageUrl3",
        "imageUrl2": "imageUrl2",
        "imageUrl": "imageUrl"
    },
    "app-content-verse": {
        "id": "id",
        "url": "url",
        "reference": "reference",
        "text": "text",
        "categories": "categories",
        "topics": "topics"
    },
    "app-user": {
        "id": "id",
        "createdAt": "createdAt",
        "email": "email",
        "password": "password",
        "needChangePassword": "needChangePassword",
        "aff": "aff",
        "devices": "devices",
        "name": "name",
        "devicesTypes": "devicesTypes",
        "imageUrl": "imageUrl",
        "isPaid": "isPaid"
    },
    "app-user-device": {
        "id": "id",
        "createdAt": "createdAt",
        "deviceName": "deviceName",
        "deviceType": "deviceType",
        "deviceUuid": "deviceUuid",
        "pushToken": "pushToken",
        "timezone": "timezone",
        "dayPrayer": "dayPrayer",
        "nightPrayer": "nightPrayer",
        "needLogOut": "needLogOut",
        "morningPrayerTime": "morningPrayerTime",
        "dayPrayerTime": "dayPrayerTime",
        "nightPrayerTime": "nightPrayerTime",
        "deviceId": "deviceId",
        "morningPrayer": "morningPrayer",
        "appUser": "appUser"
    },
    "subscription": {
        "id": "id",
        "externalId": "externalId",
        "source": "source",
        "periodStart": "periodStart",
        "periodEnd": "periodEnd",
        "status": "status",
        "email": "email",
        "planId": "planId",
        "createdAt": "createdAt"
    },
    "user": {
        "id": "id",
        "email": "email",
        "password": "password",
        "plainPassword": "plainPassword",
        "disabled": "disabled",
        "firstName": "firstName",
        "lastName": "lastName",
        "login": "login",
        "fullName": "fullName",
        "scopes": "scopes",
        "position": "position",
        "phone": "phone",
        "allowedIp": "allowedIp",
        "mailSignature": "mailSignature",
        "permissionGroup": "permissionGroup",
        "superUser": "superUser"
    },
    "app-content-user-prayer-comment": {
        "id": "id",
        "appUser": "appUser",
        "prayer": "prayer",
        "comment": "comment",
        "createdAt": "createdAt",
        "userName": "userName"
    },
    "user-auth-sign-in-log": {
        "id": "id",
        "ip": "ip",
        "createdAt": "createdAt",
        "success": "success",
        "email": "email",
        "password": "password"
    },
    "app-content-topic": {
        "id": "id",
        "name": "name",
        "appContentQuotes": "appContentQuotes",
        "appContentVerses": "appContentVerses",
        "appContentSounds": "appContentSounds",
        "appContentPrays": "appContentPrays",
        "imageUrl": "imageUrl",
        "appContentArticles": "appContentArticles",
        "intro": "intro",
        "articlesCount": "articlesCount",
        "sort": "sort"
    },
    "bookmark": {
        "id": "id",
        "parentId": "parentId",
        "parentSchema": "parentSchema",
        "createdAt": "createdAt",
        "updatedAt": "updatedAt",
        "skipValidation": "skipValidation",
        "doer": "doer",
        "creator": "creator"
    },
    "app-content-article": {
        "id": "id",
        "imageUrl": "imageUrl",
        "title": "title",
        "text": "text",
        "categories": "categories",
        "topics": "topics",
        "createdAt": "createdAt",
        "updatedAt": "updatedAt",
        "skipValidation": "skipValidation",
        "doer": "doer",
        "creator": "creator",
        "intro": "intro"
    },
    "app-content-user-prayer-pray": {
        "id": "id",
        "createdAt": "createdAt",
        "prayer": "prayer",
        "appUser": "appUser"
    },
    "app-content-pray-category": {
        "id": "id",
        "name": "name",
        "appContentPrays": "appContentPrays",
        "imageUrl": "imageUrl",
        "intro": "intro",
        "articlesCount": "articlesCount",
        "sort": "sort"
    }
}