import React from "react";
import { NaeAuthWrapper, Components } from "@newageerp/nae-react-auth-wrapper";
import i18n from "./config/translates/i18n";
import AppRouting from "./AppRouting";

function App() {
  return (
    <NaeAuthWrapper i18n={i18n}>
      <Components.AppRoutes>
        <AppRouting />
      </Components.AppRoutes>
    </NaeAuthWrapper>
  );
}

export default App;
