import { UI } from '@newageerp/nae-react-ui';
import { INaeWidget } from '@newageerp/nae-react-ui/dist/interfaces';
import { NaeSSchemaMap } from '../../NaeSSchema';
import CategoryWidget from '../../../UserComponents/CategoryWidget';
import TopicWidget from '../../../UserComponents/TopicWidget';
export const AppContentQuoteWidgets: INaeWidget[] = [
    {
        schema: NaeSSchemaMap.AppContentQuote.schema,
        type: UI.Widget.WidgetType.viewRight,
        sort: 0,
        options: {
            
        },
        comp: CategoryWidget
    },
    {
        schema: NaeSSchemaMap.AppContentQuote.schema,
        type: UI.Widget.WidgetType.viewRight,
        sort: 10,
        options: {
            
        },
        comp: TopicWidget
    },
];
