import { INaeTab } from '@newageerp/nae-react-ui/dist/interfaces';
import { schemaMap } from '../../schema/schema';
import { NaeSPropertiesKeys } from '../../NaeSPropertiesKeys';
export const AppContentUserPrayerTabs: INaeTab[] = [
    {
        quickSearchFilterKeys: ['i.name', 'i.prayer'],
        schema: schemaMap.AppContentUserPrayer.schema,
        type: "main",
        fields: [
            {
                key: NaeSPropertiesKeys['app-content-user-prayer'].name,
                link: true,
            },
            {
                key: NaeSPropertiesKeys['app-content-user-prayer'].prayer,
            },
            {
                key: NaeSPropertiesKeys['app-content-user-prayer'].createdAt,
            },
            {
                key: NaeSPropertiesKeys['app-content-user-prayer'].moderated,
            },
            {
                key: NaeSPropertiesKeys['app-content-user-prayer'].praysCount,
            },
            {
                key: NaeSPropertiesKeys['app-content-user-prayer'].commentsCount,
            }
        ],
        sort: [
          {
            key: "i.id",
            value: "DESC",
          },
        ],
      },
];
