import { INaeEditSettings } from "@newageerp/nae-react-ui/dist/interfaces";
import { NaeSSchemaMap } from "../../NaeSSchema";
import { NaeSPropertiesKeys } from "../../NaeSPropertiesKeys";
export const AppContentUserPrayerCommentEditFields: INaeEditSettings[] = [
  {
    type: "main",
    schema: NaeSSchemaMap.AppContentUserPrayerComment.schema,
    fields: [
      [{ key: NaeSPropertiesKeys["app-content-user-prayer-comment"].userName }],
      [{ key: NaeSPropertiesKeys["app-content-user-prayer-comment"].comment }],
    ],
  },
];
