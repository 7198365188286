import { INaeViewSettings } from '@newageerp/nae-react-ui/dist/interfaces';
import { schemaMap } from '../../schema/schema';
import { NaeSPropertiesKeys } from '../../NaeSPropertiesKeys';

export const AppUserViewFields: INaeViewSettings[] = [
    {
        type: "main",
        schema: schemaMap.AppUser.schema,
        fields: [
          [{ key: NaeSPropertiesKeys["app-user"].name }],
          [{ key: NaeSPropertiesKeys["app-user"].email }],
          [{ key: NaeSPropertiesKeys["app-user"].imageUrl }],
        ],
      },
];
