import React, { Fragment, useState } from "react";
import { INaeWidget } from "@newageerp/nae-react-ui/dist/interfaces";
import {
  WidgetType,
  WidgetProps,
} from "@newageerp/nae-react-ui/dist/UI/Widget/NEWidgets";

import { NaeSSchemaMap } from "../../NaeSSchema";
import { UI } from "@newageerp/nae-react-ui";

const RemindPasswordWidget = (props: WidgetProps) => {
  const [reqLoading, setReqLoading] = useState(false);
  const [reqText, setReqText] = useState("");

  const doRemind = () => {
    setReqLoading(true);
    fetch("/app/mobile/auth/remind", {
      method: "POST",
      body: JSON.stringify({
        email: props.element.email,
        returnPassword: true,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        setReqLoading(false);
        if (res.psw) {
          setReqText(res.psw);
        }
      })
      .catch(() => {
        setReqLoading(false);
      });
  };
  return (
    <Fragment>
      {!!reqText && (
        <UI.Alerts.Alert bgColor={UI.Alerts.AlertBgColor.green}>
          <p>Password has been sent.</p>
          <p>New password is: {reqText}</p>
        </UI.Alerts.Alert>
      )}
      <UI.PopoverConfirm.PopoverConfirm onClick={doRemind}>
        <UI.Buttons.Button
          icon={"fal fa-fw fa-unlock"}
          iconLoading={reqLoading}
        >
          Password remind
        </UI.Buttons.Button>
      </UI.PopoverConfirm.PopoverConfirm>
    </Fragment>
  );
};

export const AppUserWidgets: INaeWidget[] = [
  {
    schema: NaeSSchemaMap.AppUser.schema,
    // @ts-ignore
    type: "mainRightButtons",
    comp: RemindPasswordWidget,
    options: {},
    sort: 0,
  },
];
