import React, { Fragment, useEffect } from 'react'
import { UI, Hooks as UIHooks, UIConfig } from '@newageerp/nae-react-ui';
import { Hooks, Components } from "@newageerp/nae-react-auth-wrapper";
import WelcomePage from './Components/WelcomePage';

import MenuComponent from './Components/Menu/MenuComponent';
import InitComponent from './Components/Init/InitComponent';
import { DataCacheProvider } from './Components/Hooks/DataCacheProvider';

export default function AppRouting() {

  const [, , doLogin] = Hooks.useULogin();
  const isMount = UIHooks.useDidMount();

  useEffect(() => {
    if (isMount) {
      UI.Socket.Service.connect();
    }
  }, []);

  const UserSpaceWrapper = (
    <UI.Layout.LeftSideMenuToolbar
      lefSide={MenuComponent}
      contentClassName={"px-4 pt-20 pb-4"}
    />
  );

  return (
    <UI.UIBuilder.UIBuilderProvider>
      <InitComponent>
        <DataCacheProvider>
            <UI.Window.NaeWindowProvider>
              <Fragment>
                <Components.AppLoginRoutes
                  LoginPageComponent={<UI.Pages.LoginPage onSubmit={doLogin} />}
                />
                <Components.AppUserRoutes
                  Routes={[
                    ...UIConfig.getDefaultBuilderRoutes()
                  ]}
                  UserSpaceWrapper={UserSpaceWrapper}
                  UserSpaceRoutes={
                    [
                      ...UIConfig.getDefaultAppRoutes(),
                      {
                        path: "/u/:schema/:type/list/",
                        exact: true,
                        comp: <UI.RoutePoints.List />,
                      },
                      {
                        path: "/u/:schema/:type/view/:id",
                        exact: true,
                        comp: <UI.RoutePoints.View />,
                      },
                      {
                        path: "/u/:schema/:type/edit/:id",
                        exact: true,
                        comp: <UI.RoutePoints.Edit />,
                      },
                      {
                        exact: true,
                        path: '/',
                        comp: <WelcomePage />
                      },
                    ]
                  }
                />
              </Fragment>
            </UI.Window.NaeWindowProvider>
        </DataCacheProvider>
      </InitComponent>
    </UI.UIBuilder.UIBuilderProvider>
  )
}
