import { useEffect } from "react";
import { NaeSSchemaMap } from "../../config/NaeSSchema";
import { useDataCache } from "./DataCacheProvider";
import { useSelector } from "react-redux";
import { SelectorAppUserDeviceNae } from "../Models/ormSelectors";

interface ChildId {
  id: number
}

export interface IAppUserDeviceModelNae {
    id: number,

}

export const IAppUserDeviceFieldsNae = ["id"];

export function useAppUserDeviceHookNae(id: number) : IAppUserDeviceModelNae {
  // @ts-ignore
  const element = useSelector((state) => SelectorAppUserDeviceNae(state, id));

  const { addToQueue } = useDataCache();

  useEffect(() => {
    if (!element) {
      addToQueue({
        elementId: id,
        schema: NaeSSchemaMap.AppUserDevice.schema,
        fields: IAppUserDeviceFieldsNae,
      });
    }
  }, [element, id]);

  return element;
}
