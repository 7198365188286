import { INaeTab } from "@newageerp/nae-react-ui/dist/interfaces";
import { schemaMap } from "../../schema/schema";
import { propertiesKeys } from "../../properties/propertiesKeys";
export const AppContentPrayTabs: INaeTab[] = [
  {
    quickSearchFilterKeys: ['i.reference', 'i.text'],
    fields: [
      { key: propertiesKeys["app-content-pray"].reference, link: true },
      { key: propertiesKeys["app-content-pray"].text },
    ],
    sort: [
      {
        key: "i.id",
        value: "ASC",
      },
    ],
    schema: schemaMap.AppContentPray.schema,
    type: "main",
  },
];
