import { INaeViewSettings } from '@newageerp/nae-react-ui/dist/interfaces';
import { NaeSSchemaMap } from '../../NaeSSchema';
import { NaeSPropertiesKeys } from '../../NaeSPropertiesKeys';
export const AppContentCategoryViewFields: INaeViewSettings[] = [
    {
        schema: NaeSSchemaMap.AppContentCategory.schema,
        type: "main",
        fields: [[{ key: NaeSPropertiesKeys["app-content-category"].name }],
        [{ key: NaeSPropertiesKeys["app-content-category"].sort }],
        [{ key: NaeSPropertiesKeys["app-content-category"].imageUrl }],
        [{ key: NaeSPropertiesKeys["app-content-category"].intro }]
      ],
      },
];
