import { INaeTab } from "@newageerp/nae-react-ui/dist/interfaces";
import { propertiesKeys } from "../../properties/propertiesKeys";
import { schemaMap } from "../../schema/schema";
export const AppContentQuoteTabs: INaeTab[] = [
  {
    quickSearchFilterKeys: ['i.url'],
    fields: [{ key: propertiesKeys["app-content-quote"].url, link: true }],
    sort: [
      {
        key: "i.id",
        value: "ASC",
      },
    ],
    schema: schemaMap.AppContentQuote.schema,
    type: "main",
  },
];
