import { INaeEditSettings } from "@newageerp/nae-react-ui/dist/interfaces";
import { schemaMap } from "../../schema/schema";
import { propertiesKeys } from "../../properties/propertiesKeys";
export const AppContentQuoteEditFields: INaeEditSettings[] = [
  {
    type: "main",
    schema: schemaMap.AppContentQuote.schema,
    fields: [
      [
        {
          key: propertiesKeys["app-content-quote"].url,
        },
      ],
    ],
  },
];
