import { ORM } from "redux-orm";
import AppContentArticleModel from "./AppContentArticleModel";
  import AppContentTopicModel from "./AppContentTopicModel";
  import AppUserDeviceModel from "./AppUserDeviceModel";
  import AppUserModel from "./AppUserModel";
  import QueueModel from "./QueueModel";
  

export const orm = new ORM({
  stateSelector: (state) => {
    return state.orm; // wherever the reducer is put during createStore
  },
});
orm.register(
  AppContentArticleModel,
  AppContentTopicModel,
  AppUserDeviceModel,
  AppUserModel,
  QueueModel,
  
);

export default orm;
