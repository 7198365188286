import { INaeProperty, INaePropertyEnum } from "@newageerp/nae-react-ui/dist/interfaces";
export const NaeSProperties: INaeProperty[] = [
    {
        "schema": "abondded-cart-email",
        "key": "attrs",
        "type": "array",
        "format": "string",
        "title": "Get the value of attrs",
        "additionalProperties": [],
        "description": "",
        "className": "AbonddedCartEmail",
        "isDb": true,
        "dbType": "json"
    },
    {
        "schema": "abondded-cart-email",
        "key": "createdAt",
        "type": "string",
        "format": "date-time",
        "title": "Created",
        "additionalProperties": [],
        "description": "",
        "className": "AbonddedCartEmail",
        "isDb": true,
        "dbType": "datetime"
    },
    {
        "schema": "abondded-cart-email",
        "key": "email",
        "type": "string",
        "format": "",
        "title": "Email",
        "additionalProperties": [],
        "description": "",
        "className": "AbonddedCartEmail",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "abondded-cart-email",
        "key": "hasOrder",
        "type": "boolean",
        "format": "",
        "title": "Get the value of hasOrder",
        "additionalProperties": [],
        "description": "",
        "className": "AbonddedCartEmail",
        "isDb": true,
        "dbType": "tinyint"
    },
    {
        "schema": "abondded-cart-email",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "AbonddedCartEmail",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "abondded-cart-email",
        "key": "synced",
        "type": "boolean",
        "format": "",
        "title": "Get the value of synced",
        "additionalProperties": [],
        "description": "",
        "className": "AbonddedCartEmail",
        "isDb": true,
        "dbType": "tinyint"
    },
    {
        "schema": "app-content",
        "key": "content",
        "type": "array",
        "format": "string",
        "title": "Content",
        "additionalProperties": [],
        "description": "",
        "className": "AppContent",
        "isDb": true,
        "dbType": "json"
    },
    {
        "schema": "app-content",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "AppContent",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "app-content",
        "key": "longText",
        "type": "string",
        "format": "",
        "title": "Long text",
        "additionalProperties": [],
        "description": "",
        "className": "AppContent",
        "isDb": true,
        "dbType": "longtext"
    },
    {
        "schema": "app-content",
        "key": "tags",
        "type": "array",
        "format": "string",
        "title": "Tags",
        "additionalProperties": [],
        "description": "",
        "className": "AppContent",
        "isDb": true,
        "dbType": "json"
    },
    {
        "schema": "app-content-article",
        "key": "categories",
        "type": "array",
        "format": "",
        "title": "Get the value of categories",
        "additionalProperties": [],
        "description": "",
        "className": "AppContentArticle",
        "isDb": false,
        "dbType": ""
    },
    {
        "schema": "app-content-article",
        "key": "createdAt",
        "type": "string",
        "format": "datetime",
        "title": "Created At",
        "additionalProperties": [],
        "description": "",
        "className": "AppContentArticle",
        "isDb": true,
        "dbType": "datetime"
    },
    {
        "schema": "app-content-article",
        "key": "creator",
        "type": "rel",
        "format": "user",
        "title": "Get the value of creator",
        "additionalProperties": [],
        "description": "",
        "className": "AppContentArticle",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "app-content-article",
        "key": "doer",
        "type": "rel",
        "format": "user",
        "title": "Get the value of doer",
        "additionalProperties": [],
        "description": "",
        "className": "AppContentArticle",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "app-content-article",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "AppContentArticle",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "app-content-article",
        "key": "imageUrl",
        "type": "string",
        "format": "",
        "title": "Image url",
        "additionalProperties": [
            {
                "as": "image"
            }
        ],
        "description": "",
        "className": "AppContentArticle",
        "isDb": true,
        "dbType": "varchar",
        "as": "image"
    },
    {
        "schema": "app-content-article",
        "key": "intro",
        "type": "string",
        "format": "text",
        "title": "Intro",
        "additionalProperties": [],
        "description": "",
        "className": "AppContentArticle",
        "isDb": true,
        "dbType": "longtext"
    },
    {
        "schema": "app-content-article",
        "key": "skipValidation",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "AppContentArticle",
        "isDb": false,
        "dbType": ""
    },
    {
        "schema": "app-content-article",
        "key": "text",
        "type": "string",
        "format": "text",
        "title": "Text",
        "additionalProperties": [
            {
                "as": "rich_editor"
            }
        ],
        "description": "",
        "className": "AppContentArticle",
        "isDb": true,
        "dbType": "longtext",
        "as": "rich_editor"
    },
    {
        "schema": "app-content-article",
        "key": "title",
        "type": "string",
        "format": "",
        "title": "Title",
        "additionalProperties": [],
        "description": "",
        "className": "AppContentArticle",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "app-content-article",
        "key": "topics",
        "type": "array",
        "format": "",
        "title": "Get the value of topics",
        "additionalProperties": [],
        "description": "",
        "className": "AppContentArticle",
        "isDb": false,
        "dbType": ""
    },
    {
        "schema": "app-content-article",
        "key": "updatedAt",
        "type": "string",
        "format": "datetime",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "AppContentArticle",
        "isDb": true,
        "dbType": "datetime"
    },
    {
        "schema": "app-content-bible-chapter",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "AppContentBibleChapter",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "app-content-bible-chapter",
        "key": "number",
        "type": "integer",
        "format": "",
        "title": "Number",
        "additionalProperties": [],
        "description": "",
        "className": "AppContentBibleChapter",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "app-content-bible-chapter",
        "key": "testament",
        "type": "rel",
        "format": "app-content-bible-testament",
        "title": "Testament",
        "additionalProperties": [],
        "description": "",
        "className": "AppContentBibleChapter",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "app-content-bible-chapter",
        "key": "title",
        "type": "string",
        "format": "",
        "title": "Title",
        "additionalProperties": [],
        "description": "",
        "className": "AppContentBibleChapter",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "app-content-bible-chapter",
        "key": "versesCount",
        "type": "integer",
        "format": "",
        "title": "Verses",
        "additionalProperties": [],
        "description": "",
        "className": "AppContentBibleChapter",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "app-content-bible-testament",
        "key": "chapters",
        "type": "array",
        "format": "",
        "title": "Chapters",
        "additionalProperties": [],
        "description": "",
        "className": "AppContentBibleTestament",
        "isDb": false,
        "dbType": ""
    },
    {
        "schema": "app-content-bible-testament",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "AppContentBibleTestament",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "app-content-bible-testament",
        "key": "title",
        "type": "string",
        "format": "",
        "title": "Title",
        "additionalProperties": [],
        "description": "",
        "className": "AppContentBibleTestament",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "app-content-bible-verse",
        "key": "chapter",
        "type": "rel",
        "format": "app-content-bible-chapter",
        "title": "Chapter",
        "additionalProperties": [],
        "description": "",
        "className": "AppContentBibleVerse",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "app-content-bible-verse",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "AppContentBibleVerse",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "app-content-bible-verse",
        "key": "line",
        "type": "string",
        "format": "",
        "title": "Text",
        "additionalProperties": [],
        "description": "",
        "className": "AppContentBibleVerse",
        "isDb": true,
        "dbType": "longtext"
    },
    {
        "schema": "app-content-bible-verse",
        "key": "lineNumber",
        "type": "integer",
        "format": "",
        "title": "Line",
        "additionalProperties": [],
        "description": "",
        "className": "AppContentBibleVerse",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "app-content-bible-verse",
        "key": "number",
        "type": "integer",
        "format": "",
        "title": "Number",
        "additionalProperties": [],
        "description": "",
        "className": "AppContentBibleVerse",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "app-content-category",
        "key": "appContentArticles",
        "type": "array",
        "format": "",
        "title": "Get the value of appContentArticles",
        "additionalProperties": [],
        "description": "",
        "className": "AppContentCategory",
        "isDb": false,
        "dbType": ""
    },
    {
        "schema": "app-content-category",
        "key": "appContentPrays",
        "type": "array",
        "format": "",
        "title": "Get the value of appContentPrays",
        "additionalProperties": [],
        "description": "",
        "className": "AppContentCategory",
        "isDb": false,
        "dbType": ""
    },
    {
        "schema": "app-content-category",
        "key": "appContentQuotes",
        "type": "array",
        "format": "",
        "title": "Get the value of appContentQuotes",
        "additionalProperties": [],
        "description": "",
        "className": "AppContentCategory",
        "isDb": false,
        "dbType": ""
    },
    {
        "schema": "app-content-category",
        "key": "appContentSounds",
        "type": "array",
        "format": "",
        "title": "Get the value of appContentSounds",
        "additionalProperties": [],
        "description": "",
        "className": "AppContentCategory",
        "isDb": false,
        "dbType": ""
    },
    {
        "schema": "app-content-category",
        "key": "appContentVerses",
        "type": "array",
        "format": "",
        "title": "Get the value of appContentVerses",
        "additionalProperties": [],
        "description": "",
        "className": "AppContentCategory",
        "isDb": false,
        "dbType": ""
    },
    {
        "schema": "app-content-category",
        "key": "articlesCount",
        "type": "integer",
        "format": "",
        "title": "Get the value of articlesCount",
        "additionalProperties": [],
        "description": "",
        "className": "AppContentCategory",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "app-content-category",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "AppContentCategory",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "app-content-category",
        "key": "imageUrl",
        "type": "string",
        "format": "",
        "title": "Image url",
        "additionalProperties": [
            {
                "as": "image"
            }
        ],
        "description": "",
        "className": "AppContentCategory",
        "isDb": true,
        "dbType": "varchar",
        "as": "image"
    },
    {
        "schema": "app-content-category",
        "key": "intro",
        "type": "string",
        "format": "text",
        "title": "Intro",
        "additionalProperties": [],
        "description": "",
        "className": "AppContentCategory",
        "isDb": true,
        "dbType": "longtext"
    },
    {
        "schema": "app-content-category",
        "key": "name",
        "type": "string",
        "format": "",
        "title": "Name",
        "additionalProperties": [],
        "description": "",
        "className": "AppContentCategory",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "app-content-category",
        "key": "sort",
        "type": "integer",
        "format": "",
        "title": "Sort",
        "additionalProperties": [],
        "description": "",
        "className": "AppContentCategory",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "app-content-pray",
        "key": "categories",
        "type": "array",
        "format": "",
        "title": "Get the value of categories",
        "additionalProperties": [],
        "description": "",
        "className": "AppContentPray",
        "isDb": false,
        "dbType": ""
    },
    {
        "schema": "app-content-pray",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "AppContentPray",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "app-content-pray",
        "key": "imageUrl",
        "type": "string",
        "format": "",
        "title": "Image url",
        "additionalProperties": [
            {
                "as": "image"
            }
        ],
        "description": "",
        "className": "AppContentPray",
        "isDb": true,
        "dbType": "varchar",
        "as": "image"
    },
    {
        "schema": "app-content-pray",
        "key": "prayCategories",
        "type": "array",
        "format": "",
        "title": "Get the value of prayCategories",
        "additionalProperties": [],
        "description": "",
        "className": "AppContentPray",
        "isDb": false,
        "dbType": ""
    },
    {
        "schema": "app-content-pray",
        "key": "reference",
        "type": "string",
        "format": "",
        "title": "Reference",
        "additionalProperties": [],
        "description": "",
        "className": "AppContentPray",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "app-content-pray",
        "key": "text",
        "type": "string",
        "format": "text",
        "title": "Text",
        "additionalProperties": [],
        "description": "",
        "className": "AppContentPray",
        "isDb": true,
        "dbType": "longtext"
    },
    {
        "schema": "app-content-pray",
        "key": "topics",
        "type": "array",
        "format": "",
        "title": "Get the value of topics",
        "additionalProperties": [],
        "description": "",
        "className": "AppContentPray",
        "isDb": false,
        "dbType": ""
    },
    {
        "schema": "app-content-pray-category",
        "key": "appContentPrays",
        "type": "array",
        "format": "",
        "title": "Get the value of appContentPrays",
        "additionalProperties": [],
        "description": "",
        "className": "AppContentPrayCategory",
        "isDb": false,
        "dbType": ""
    },
    {
        "schema": "app-content-pray-category",
        "key": "articlesCount",
        "type": "integer",
        "format": "",
        "title": "Get the value of articlesCount",
        "additionalProperties": [],
        "description": "",
        "className": "AppContentPrayCategory",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "app-content-pray-category",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "AppContentPrayCategory",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "app-content-pray-category",
        "key": "imageUrl",
        "type": "string",
        "format": "",
        "title": "Image url",
        "additionalProperties": [
            {
                "as": "image"
            }
        ],
        "description": "",
        "className": "AppContentPrayCategory",
        "isDb": true,
        "dbType": "varchar",
        "as": "image"
    },
    {
        "schema": "app-content-pray-category",
        "key": "intro",
        "type": "string",
        "format": "text",
        "title": "Intro",
        "additionalProperties": [],
        "description": "",
        "className": "AppContentPrayCategory",
        "isDb": true,
        "dbType": "longtext"
    },
    {
        "schema": "app-content-pray-category",
        "key": "name",
        "type": "string",
        "format": "",
        "title": "Name",
        "additionalProperties": [],
        "description": "",
        "className": "AppContentPrayCategory",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "app-content-pray-category",
        "key": "sort",
        "type": "integer",
        "format": "",
        "title": "Sort",
        "additionalProperties": [],
        "description": "",
        "className": "AppContentPrayCategory",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "app-content-quote",
        "key": "categories",
        "type": "array",
        "format": "",
        "title": "Get the value of categories",
        "additionalProperties": [],
        "description": "",
        "className": "AppContentQuote",
        "isDb": false,
        "dbType": ""
    },
    {
        "schema": "app-content-quote",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "AppContentQuote",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "app-content-quote",
        "key": "topics",
        "type": "array",
        "format": "",
        "title": "Get the value of topics",
        "additionalProperties": [],
        "description": "",
        "className": "AppContentQuote",
        "isDb": false,
        "dbType": ""
    },
    {
        "schema": "app-content-quote",
        "key": "url",
        "type": "string",
        "format": "",
        "title": "Image url",
        "additionalProperties": [
            {
                "as": "image"
            }
        ],
        "description": "",
        "className": "AppContentQuote",
        "isDb": true,
        "dbType": "varchar",
        "as": "image"
    },
    {
        "schema": "app-content-sound",
        "key": "audio",
        "type": "string",
        "format": "",
        "title": "Audio url",
        "additionalProperties": [
            {
                "as": "audio"
            }
        ],
        "description": "",
        "className": "AppContentSound",
        "isDb": true,
        "dbType": "varchar",
        "as": "audio"
    },
    {
        "schema": "app-content-sound",
        "key": "categories",
        "type": "array",
        "format": "",
        "title": "Get the value of categories",
        "additionalProperties": [],
        "description": "",
        "className": "AppContentSound",
        "isDb": false,
        "dbType": ""
    },
    {
        "schema": "app-content-sound",
        "key": "duration",
        "type": "string",
        "format": "",
        "title": "Duration",
        "additionalProperties": [],
        "description": "",
        "className": "AppContentSound",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "app-content-sound",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "AppContentSound",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "app-content-sound",
        "key": "subTitle",
        "type": "string",
        "format": "",
        "title": "Title",
        "additionalProperties": [],
        "description": "",
        "className": "AppContentSound",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "app-content-sound",
        "key": "title",
        "type": "string",
        "format": "",
        "title": "Title",
        "additionalProperties": [],
        "description": "",
        "className": "AppContentSound",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "app-content-sound",
        "key": "topics",
        "type": "array",
        "format": "",
        "title": "Get the value of topics",
        "additionalProperties": [],
        "description": "",
        "className": "AppContentSound",
        "isDb": false,
        "dbType": ""
    },
    {
        "schema": "app-content-sound",
        "key": "url",
        "type": "string",
        "format": "",
        "title": "Image url",
        "additionalProperties": [
            {
                "as": "image"
            }
        ],
        "description": "",
        "className": "AppContentSound",
        "isDb": true,
        "dbType": "varchar",
        "as": "image"
    },
    {
        "schema": "app-content-topic",
        "key": "appContentArticles",
        "type": "array",
        "format": "",
        "title": "Get the value of appContentArticles",
        "additionalProperties": [],
        "description": "",
        "className": "AppContentTopic",
        "isDb": false,
        "dbType": ""
    },
    {
        "schema": "app-content-topic",
        "key": "appContentPrays",
        "type": "array",
        "format": "",
        "title": "Get the value of appContentPrays",
        "additionalProperties": [],
        "description": "",
        "className": "AppContentTopic",
        "isDb": false,
        "dbType": ""
    },
    {
        "schema": "app-content-topic",
        "key": "appContentQuotes",
        "type": "array",
        "format": "",
        "title": "Get the value of appContentQuotes",
        "additionalProperties": [],
        "description": "",
        "className": "AppContentTopic",
        "isDb": false,
        "dbType": ""
    },
    {
        "schema": "app-content-topic",
        "key": "appContentSounds",
        "type": "array",
        "format": "",
        "title": "Get the value of appContentSounds",
        "additionalProperties": [],
        "description": "",
        "className": "AppContentTopic",
        "isDb": false,
        "dbType": ""
    },
    {
        "schema": "app-content-topic",
        "key": "appContentVerses",
        "type": "array",
        "format": "",
        "title": "Get the value of appContentVerses",
        "additionalProperties": [],
        "description": "",
        "className": "AppContentTopic",
        "isDb": false,
        "dbType": ""
    },
    {
        "schema": "app-content-topic",
        "key": "articlesCount",
        "type": "integer",
        "format": "",
        "title": "Get the value of articlesCount",
        "additionalProperties": [],
        "description": "",
        "className": "AppContentTopic",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "app-content-topic",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "AppContentTopic",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "app-content-topic",
        "key": "imageUrl",
        "type": "string",
        "format": "",
        "title": "Image url",
        "additionalProperties": [
            {
                "as": "image"
            }
        ],
        "description": "",
        "className": "AppContentTopic",
        "isDb": true,
        "dbType": "varchar",
        "as": "image"
    },
    {
        "schema": "app-content-topic",
        "key": "intro",
        "type": "string",
        "format": "text",
        "title": "Intro",
        "additionalProperties": [],
        "description": "",
        "className": "AppContentTopic",
        "isDb": true,
        "dbType": "longtext"
    },
    {
        "schema": "app-content-topic",
        "key": "name",
        "type": "string",
        "format": "",
        "title": "Name",
        "additionalProperties": [],
        "description": "",
        "className": "AppContentTopic",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "app-content-topic",
        "key": "sort",
        "type": "integer",
        "format": "",
        "title": "Sort",
        "additionalProperties": [],
        "description": "",
        "className": "AppContentTopic",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "app-content-user-prayer",
        "key": "appUser",
        "type": "rel",
        "format": "app-user",
        "title": "User",
        "additionalProperties": [],
        "description": "",
        "className": "AppContentUserPrayer",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "app-content-user-prayer",
        "key": "comments",
        "type": "array",
        "format": "",
        "title": "Get the value of comments",
        "additionalProperties": [],
        "description": "",
        "className": "AppContentUserPrayer",
        "isDb": false,
        "dbType": ""
    },
    {
        "schema": "app-content-user-prayer",
        "key": "commentsCount",
        "type": "integer",
        "format": "",
        "title": "Comments count",
        "additionalProperties": [],
        "description": "",
        "className": "AppContentUserPrayer",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "app-content-user-prayer",
        "key": "createdAt",
        "type": "string",
        "format": "datetime",
        "title": "Created",
        "additionalProperties": [],
        "description": "",
        "className": "AppContentUserPrayer",
        "isDb": true,
        "dbType": "datetime"
    },
    {
        "schema": "app-content-user-prayer",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "AppContentUserPrayer",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "app-content-user-prayer",
        "key": "imageUrl",
        "type": "string",
        "format": "",
        "title": "Image url",
        "additionalProperties": [
            {
                "as": "image"
            }
        ],
        "description": "",
        "className": "AppContentUserPrayer",
        "isDb": true,
        "dbType": "varchar",
        "as": "image"
    },
    {
        "schema": "app-content-user-prayer",
        "key": "imageUrl2",
        "type": "string",
        "format": "",
        "title": "Image url",
        "additionalProperties": [
            {
                "as": "image"
            }
        ],
        "description": "",
        "className": "AppContentUserPrayer",
        "isDb": false,
        "dbType": "",
        "as": "image"
    },
    {
        "schema": "app-content-user-prayer",
        "key": "imageUrl3",
        "type": "string",
        "format": "",
        "title": "Image url",
        "additionalProperties": [
            {
                "as": "image"
            }
        ],
        "description": "",
        "className": "AppContentUserPrayer",
        "isDb": false,
        "dbType": "",
        "as": "image"
    },
    {
        "schema": "app-content-user-prayer",
        "key": "imageUrl4",
        "type": "string",
        "format": "",
        "title": "Image url",
        "additionalProperties": [
            {
                "as": "image"
            }
        ],
        "description": "",
        "className": "AppContentUserPrayer",
        "isDb": false,
        "dbType": "",
        "as": "image"
    },
    {
        "schema": "app-content-user-prayer",
        "key": "imageUrl5",
        "type": "string",
        "format": "",
        "title": "Image url",
        "additionalProperties": [
            {
                "as": "image"
            }
        ],
        "description": "",
        "className": "AppContentUserPrayer",
        "isDb": false,
        "dbType": "",
        "as": "image"
    },
    {
        "schema": "app-content-user-prayer",
        "key": "location",
        "type": "string",
        "format": "",
        "title": "Location",
        "additionalProperties": [],
        "description": "",
        "className": "AppContentUserPrayer",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "app-content-user-prayer",
        "key": "moderated",
        "type": "boolean",
        "format": "",
        "title": "Moderated",
        "additionalProperties": [],
        "description": "",
        "className": "AppContentUserPrayer",
        "isDb": true,
        "dbType": "tinyint"
    },
    {
        "schema": "app-content-user-prayer",
        "key": "name",
        "type": "string",
        "format": "",
        "title": "Name",
        "additionalProperties": [],
        "description": "",
        "className": "AppContentUserPrayer",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "app-content-user-prayer",
        "key": "prayer",
        "type": "string",
        "format": "",
        "title": "Prayer",
        "additionalProperties": [],
        "description": "",
        "className": "AppContentUserPrayer",
        "isDb": true,
        "dbType": "longtext"
    },
    {
        "schema": "app-content-user-prayer",
        "key": "prays",
        "type": "array",
        "format": "",
        "title": "Get the value of prays",
        "additionalProperties": [],
        "description": "",
        "className": "AppContentUserPrayer",
        "isDb": false,
        "dbType": ""
    },
    {
        "schema": "app-content-user-prayer",
        "key": "praysCount",
        "type": "integer",
        "format": "",
        "title": "Prays count",
        "additionalProperties": [],
        "description": "",
        "className": "AppContentUserPrayer",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "app-content-user-prayer-comment",
        "key": "appUser",
        "type": "rel",
        "format": "app-user",
        "title": "User",
        "additionalProperties": [],
        "description": "",
        "className": "AppContentUserPrayerComment",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "app-content-user-prayer-comment",
        "key": "comment",
        "type": "string",
        "format": "",
        "title": "Comment",
        "additionalProperties": [],
        "description": "",
        "className": "AppContentUserPrayerComment",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "app-content-user-prayer-comment",
        "key": "createdAt",
        "type": "string",
        "format": "date",
        "title": "Created",
        "additionalProperties": [],
        "description": "",
        "className": "AppContentUserPrayerComment",
        "isDb": true,
        "dbType": "date"
    },
    {
        "schema": "app-content-user-prayer-comment",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "AppContentUserPrayerComment",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "app-content-user-prayer-comment",
        "key": "prayer",
        "type": "rel",
        "format": "app-content-user-prayer",
        "title": "Prayer",
        "additionalProperties": [],
        "description": "",
        "className": "AppContentUserPrayerComment",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "app-content-user-prayer-comment",
        "key": "userName",
        "type": "string",
        "format": "",
        "title": "User Name",
        "additionalProperties": [],
        "description": "",
        "className": "AppContentUserPrayerComment",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "app-content-user-prayer-pray",
        "key": "appUser",
        "type": "rel",
        "format": "app-user",
        "title": "User",
        "additionalProperties": [],
        "description": "",
        "className": "AppContentUserPrayerPray",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "app-content-user-prayer-pray",
        "key": "createdAt",
        "type": "string",
        "format": "date",
        "title": "Created",
        "additionalProperties": [],
        "description": "",
        "className": "AppContentUserPrayerPray",
        "isDb": true,
        "dbType": "date"
    },
    {
        "schema": "app-content-user-prayer-pray",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "AppContentUserPrayerPray",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "app-content-user-prayer-pray",
        "key": "prayer",
        "type": "rel",
        "format": "app-content-user-prayer",
        "title": "Prayer",
        "additionalProperties": [],
        "description": "",
        "className": "AppContentUserPrayerPray",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "app-content-verse",
        "key": "categories",
        "type": "array",
        "format": "",
        "title": "Get the value of categories",
        "additionalProperties": [],
        "description": "",
        "className": "AppContentVerse",
        "isDb": false,
        "dbType": ""
    },
    {
        "schema": "app-content-verse",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "AppContentVerse",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "app-content-verse",
        "key": "reference",
        "type": "string",
        "format": "",
        "title": "Reference",
        "additionalProperties": [],
        "description": "",
        "className": "AppContentVerse",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "app-content-verse",
        "key": "text",
        "type": "string",
        "format": "text",
        "title": "Text",
        "additionalProperties": [],
        "description": "",
        "className": "AppContentVerse",
        "isDb": true,
        "dbType": "longtext"
    },
    {
        "schema": "app-content-verse",
        "key": "topics",
        "type": "array",
        "format": "",
        "title": "Get the value of topics",
        "additionalProperties": [],
        "description": "",
        "className": "AppContentVerse",
        "isDb": false,
        "dbType": ""
    },
    {
        "schema": "app-content-verse",
        "key": "url",
        "type": "string",
        "format": "",
        "title": "Image url",
        "additionalProperties": [
            {
                "as": "image"
            }
        ],
        "description": "",
        "className": "AppContentVerse",
        "isDb": true,
        "dbType": "varchar",
        "as": "image"
    },
    {
        "schema": "app-user",
        "key": "aff",
        "type": "string",
        "format": "",
        "title": "Aff",
        "additionalProperties": [],
        "description": "",
        "className": "AppUser",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "app-user",
        "key": "createdAt",
        "type": "string",
        "format": "datetime",
        "title": "Created",
        "additionalProperties": [],
        "description": "",
        "className": "AppUser",
        "isDb": true,
        "dbType": "datetime"
    },
    {
        "schema": "app-user",
        "key": "devices",
        "type": "array",
        "format": "",
        "title": "Devices",
        "additionalProperties": [],
        "description": "",
        "className": "AppUser",
        "isDb": false,
        "dbType": ""
    },
    {
        "schema": "app-user",
        "key": "devicesTypes",
        "type": "string",
        "format": "",
        "title": "Device",
        "additionalProperties": [],
        "description": "",
        "className": "AppUser",
        "isDb": false,
        "dbType": ""
    },
    {
        "schema": "app-user",
        "key": "email",
        "type": "string",
        "format": "",
        "title": "Email",
        "additionalProperties": [],
        "description": "",
        "className": "AppUser",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "app-user",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "AppUser",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "app-user",
        "key": "imageUrl",
        "type": "string",
        "format": "",
        "title": "Image url",
        "additionalProperties": [
            {
                "as": "image"
            }
        ],
        "description": "",
        "className": "AppUser",
        "isDb": true,
        "dbType": "varchar",
        "as": "image"
    },
    {
        "schema": "app-user",
        "key": "isPaid",
        "type": "boolean",
        "format": "",
        "title": "Get the value of isPaid",
        "additionalProperties": [],
        "description": "",
        "className": "AppUser",
        "isDb": true,
        "dbType": "tinyint"
    },
    {
        "schema": "app-user",
        "key": "name",
        "type": "string",
        "format": "",
        "title": "Name",
        "additionalProperties": [],
        "description": "",
        "className": "AppUser",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "app-user",
        "key": "needChangePassword",
        "type": "boolean",
        "format": "",
        "title": "Get the value of needChangePassword",
        "additionalProperties": [],
        "description": "",
        "className": "AppUser",
        "isDb": true,
        "dbType": "tinyint"
    },
    {
        "schema": "app-user",
        "key": "password",
        "type": "string",
        "format": "",
        "title": "Password",
        "additionalProperties": [],
        "description": "",
        "className": "AppUser",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "app-user-device",
        "key": "appUser",
        "type": "rel",
        "format": "app-user",
        "title": "User",
        "additionalProperties": [],
        "description": "",
        "className": "AppUserDevice",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "app-user-device",
        "key": "createdAt",
        "type": "string",
        "format": "date-time",
        "title": "Created",
        "additionalProperties": [],
        "description": "",
        "className": "AppUserDevice",
        "isDb": true,
        "dbType": "date"
    },
    {
        "schema": "app-user-device",
        "key": "dayPrayer",
        "type": "boolean",
        "format": "",
        "title": "Day prayer",
        "additionalProperties": [],
        "description": "",
        "className": "AppUserDevice",
        "isDb": true,
        "dbType": "tinyint"
    },
    {
        "schema": "app-user-device",
        "key": "dayPrayerTime",
        "type": "string",
        "format": "",
        "title": "Day prayer time",
        "additionalProperties": [],
        "description": "",
        "className": "AppUserDevice",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "app-user-device",
        "key": "deviceId",
        "type": "string",
        "format": "",
        "title": "Device ID",
        "additionalProperties": [],
        "description": "",
        "className": "AppUserDevice",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "app-user-device",
        "key": "deviceName",
        "type": "string",
        "format": "",
        "title": "Device name",
        "additionalProperties": [],
        "description": "",
        "className": "AppUserDevice",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "app-user-device",
        "key": "deviceType",
        "type": "string",
        "format": "",
        "title": "Device type",
        "additionalProperties": [],
        "description": "",
        "className": "AppUserDevice",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "app-user-device",
        "key": "deviceUuid",
        "type": "string",
        "format": "",
        "title": "UUID",
        "additionalProperties": [],
        "description": "",
        "className": "AppUserDevice",
        "isDb": true,
        "dbType": "longtext"
    },
    {
        "schema": "app-user-device",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "AppUserDevice",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "app-user-device",
        "key": "morningPrayer",
        "type": "boolean",
        "format": "",
        "title": "Morning prayer",
        "additionalProperties": [],
        "description": "",
        "className": "AppUserDevice",
        "isDb": true,
        "dbType": "tinyint"
    },
    {
        "schema": "app-user-device",
        "key": "morningPrayerTime",
        "type": "string",
        "format": "",
        "title": "Morning prayer time",
        "additionalProperties": [],
        "description": "",
        "className": "AppUserDevice",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "app-user-device",
        "key": "needLogOut",
        "type": "boolean",
        "format": "",
        "title": "Need logout",
        "additionalProperties": [],
        "description": "",
        "className": "AppUserDevice",
        "isDb": true,
        "dbType": "tinyint"
    },
    {
        "schema": "app-user-device",
        "key": "nightPrayer",
        "type": "boolean",
        "format": "",
        "title": "Night prayer",
        "additionalProperties": [],
        "description": "",
        "className": "AppUserDevice",
        "isDb": true,
        "dbType": "tinyint"
    },
    {
        "schema": "app-user-device",
        "key": "nightPrayerTime",
        "type": "string",
        "format": "",
        "title": "Night prayer time",
        "additionalProperties": [],
        "description": "",
        "className": "AppUserDevice",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "app-user-device",
        "key": "pushToken",
        "type": "string",
        "format": "",
        "title": "Push token",
        "additionalProperties": [],
        "description": "",
        "className": "AppUserDevice",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "app-user-device",
        "key": "timezone",
        "type": "string",
        "format": "",
        "title": "Timezone",
        "additionalProperties": [],
        "description": "",
        "className": "AppUserDevice",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "bookmark",
        "key": "createdAt",
        "type": "string",
        "format": "datetime",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Bookmark",
        "isDb": true,
        "dbType": "datetime"
    },
    {
        "schema": "bookmark",
        "key": "creator",
        "type": "rel",
        "format": "user",
        "title": "Get the value of creator",
        "additionalProperties": [],
        "description": "",
        "className": "Bookmark",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "bookmark",
        "key": "doer",
        "type": "rel",
        "format": "user",
        "title": "Get the value of doer",
        "additionalProperties": [],
        "description": "",
        "className": "Bookmark",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "bookmark",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Bookmark",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "bookmark",
        "key": "parentId",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Bookmark",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "bookmark",
        "key": "parentSchema",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Bookmark",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "bookmark",
        "key": "skipValidation",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Bookmark",
        "isDb": false,
        "dbType": ""
    },
    {
        "schema": "bookmark",
        "key": "updatedAt",
        "type": "string",
        "format": "datetime",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Bookmark",
        "isDb": true,
        "dbType": "datetime"
    },
    {
        "schema": "subscription",
        "key": "createdAt",
        "type": "string",
        "format": "date-time",
        "title": "Created",
        "additionalProperties": [],
        "description": "",
        "className": "Subscription",
        "isDb": true,
        "dbType": "date"
    },
    {
        "schema": "subscription",
        "key": "email",
        "type": "string",
        "format": "",
        "title": "Email",
        "additionalProperties": [],
        "description": "",
        "className": "Subscription",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "subscription",
        "key": "externalId",
        "type": "string",
        "format": "",
        "title": "External ID",
        "additionalProperties": [],
        "description": "",
        "className": "Subscription",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "subscription",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Subscription",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "subscription",
        "key": "periodEnd",
        "type": "string",
        "format": "date-time",
        "title": "Period END",
        "additionalProperties": [],
        "description": "",
        "className": "Subscription",
        "isDb": true,
        "dbType": "date"
    },
    {
        "schema": "subscription",
        "key": "periodStart",
        "type": "string",
        "format": "date-time",
        "title": "Period Start",
        "additionalProperties": [],
        "description": "",
        "className": "Subscription",
        "isDb": true,
        "dbType": "date"
    },
    {
        "schema": "subscription",
        "key": "planId",
        "type": "string",
        "format": "",
        "title": "Plan ID",
        "additionalProperties": [],
        "description": "",
        "className": "Subscription",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "subscription",
        "key": "source",
        "type": "string",
        "format": "",
        "title": "Source",
        "additionalProperties": [],
        "description": "",
        "className": "Subscription",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "subscription",
        "key": "status",
        "type": "string",
        "format": "",
        "title": "Status",
        "additionalProperties": [],
        "description": "",
        "className": "Subscription",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "user",
        "key": "allowedIp",
        "type": "string",
        "format": "text",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "longtext"
    },
    {
        "schema": "user",
        "key": "disabled",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "tinyint"
    },
    {
        "schema": "user",
        "key": "email",
        "type": "string",
        "format": "",
        "title": "Email",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "user",
        "key": "firstName",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "user",
        "key": "fullName",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": false,
        "dbType": ""
    },
    {
        "schema": "user",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "user",
        "key": "lastName",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "user",
        "key": "login",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "user",
        "key": "mailSignature",
        "type": "string",
        "format": "text",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "longtext"
    },
    {
        "schema": "user",
        "key": "password",
        "type": "string",
        "format": "",
        "title": "Password",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "user",
        "key": "permissionGroup",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "user",
        "key": "phone",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "user",
        "key": "plainPassword",
        "type": "string",
        "format": "password",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": false,
        "dbType": ""
    },
    {
        "schema": "user",
        "key": "position",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "user",
        "key": "scopes",
        "type": "array",
        "format": "string",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": false,
        "dbType": ""
    },
    {
        "schema": "user",
        "key": "superUser",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "tinyint"
    },
    {
        "schema": "user-auth-sign-in-log",
        "key": "createdAt",
        "type": "string",
        "format": "date-time",
        "title": "Date",
        "additionalProperties": [],
        "description": "",
        "className": "UserAuthSignInLog",
        "isDb": true,
        "dbType": "datetime"
    },
    {
        "schema": "user-auth-sign-in-log",
        "key": "email",
        "type": "string",
        "format": "",
        "title": "Email",
        "additionalProperties": [],
        "description": "",
        "className": "UserAuthSignInLog",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "user-auth-sign-in-log",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "UserAuthSignInLog",
        "isDb": true,
        "dbType": "int"
    },
    {
        "schema": "user-auth-sign-in-log",
        "key": "ip",
        "type": "string",
        "format": "",
        "title": "IP",
        "additionalProperties": [],
        "description": "",
        "className": "UserAuthSignInLog",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "user-auth-sign-in-log",
        "key": "password",
        "type": "string",
        "format": "",
        "title": "Password",
        "additionalProperties": [],
        "description": "",
        "className": "UserAuthSignInLog",
        "isDb": true,
        "dbType": "varchar"
    },
    {
        "schema": "user-auth-sign-in-log",
        "key": "success",
        "type": "boolean",
        "format": "",
        "title": "Success?",
        "additionalProperties": [],
        "description": "",
        "className": "UserAuthSignInLog",
        "isDb": true,
        "dbType": "tinyint"
    }
]