import { functions } from '@newageerp/nae-react-ui';
import { UI } from '@newageerp/nae-react-ui'
import React, { Fragment } from 'react'
import { schemaMap } from '../../config/schema/schema';
import { NaeSSchemaMap } from '../../config/NaeSSchema';
import DataCacheSocketComponent from '../Hooks/DataCacheSocketComponent';
import { useDataCache } from '../Hooks/DataCacheProvider';
import { useRecoilValue } from 'recoil';
import { OpenApi } from '@newageerp/nae-react-auth-wrapper';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

export default function MenuComponent() {
    const { queue } = useDataCache();

    const userState: any = useRecoilValue(OpenApi.naeUserState);

    const logout = () => {
        window.localStorage.setItem("token", "");
        window.location.href = "/";
    };

    const loadMainList = (schema: string) => {
        return '/u/' + schema + '/main/list'
    }

    const menuItemForSchema = (schema: string, icon?: string) => {
        return (
            <UI.Menu.MenuItem
                path={loadMainList(schema)}
                title={functions.schemas.getSchemaTitle(schema, true)}
                icon={icon}
            />
        )
    }

    const { t } = useTranslation();
    const history = useHistory();
    const goTo = (p: string) => {
        history.push(p);
    };

    return (
        <Fragment>
            <div className={"mb-4"}>
                <div className={"flex gap-2 items-center px-2"}>
                    <div className={"flex-grow text-white text-sm"}>
                        {userState.email}
                    </div>
                    <button onClick={logout}>
                        <i className={"fad fa-sign-out text-white hover:text-red-500"} />
                    </button>
                </div>

                <div className={"flex gap-4 items-center px-2"}>
                    {/* <UI.Bookmark.Toolbar userId={userState.id} /> */}

                    <button onClick={() => goTo("/c/bookmark")} title={t("Sekami")}>
                        <i className="fad fa-fw fa-bookmark text-nsecondary-100" />
                    </button>

                    <button onClick={() => goTo("/c/follow-up")} title={t("Priminimai")}>
                        <i className="fad fa-fw fa-bell text-nsecondary-100" />
                    </button>

                    {/* <UI.Tasks.Toolbar userId={userState.id} /> */}

                    {/* <NotesToolbar /> */}

                    {/* {!!userState.voximplantUser &&
                        <button onClick={togglePhoneBar} title={t("Skambučiai")}>
                            <i className="fad fa-fw fa-phone text-nsecondary-100" />
                        </button>
                    } */}

                    {queue.length > 0 && <UI.Loader.Logo size={20} />}

                </div>
            </div>

            <UI.Menu.MenuSection title={'CONTENT'} />

            {menuItemForSchema(NaeSSchemaMap.AppContentArticle.schema, 'fas fa-file')}

            {menuItemForSchema(NaeSSchemaMap.AppContentQuote.schema, 'fas fa-image')}

            {menuItemForSchema(NaeSSchemaMap.AppContentVerse.schema, 'fas fa-font-case')}

            {menuItemForSchema(NaeSSchemaMap.AppContentSound.schema, 'fas fa-headphones')}

            {menuItemForSchema(NaeSSchemaMap.AppContentPray.schema, 'fas fa-pray')}

            {menuItemForSchema(NaeSSchemaMap.AppContentUserPrayer.schema, 'fas fa-praying-hands')}

            <UI.Menu.MenuSeparator/>

            {menuItemForSchema(NaeSSchemaMap.AppContentCategory.schema, 'fas fa-bars')}

            {menuItemForSchema(NaeSSchemaMap.AppContentPrayCategory.schema, 'fas fa-bars')}

            {menuItemForSchema(NaeSSchemaMap.AppContentTopic.schema, 'fas fa-bars')}

            <UI.Menu.MenuSection title={'Bibble'} />

            {menuItemForSchema(NaeSSchemaMap.AppContentBibleTestament.schema, 'fas fa-bible')}

            {menuItemForSchema(NaeSSchemaMap.AppContentBibleChapter.schema, 'fas fa-list-ol')}

            {menuItemForSchema(NaeSSchemaMap.AppContentBibleVerse.schema, 'fas fa-paragraph')}

            <UI.Menu.MenuSection title={'APP USERS'} />

            {menuItemForSchema(NaeSSchemaMap.AppUser.schema, 'fas fa-mobile')}

            {menuItemForSchema(NaeSSchemaMap.UserAuthSignInLog.schema, 'fas fa-archive')}

            <UI.Menu.MenuSection title={'SETTINGS'} />

            {menuItemForSchema(NaeSSchemaMap.User.schema, 'fas fa-user')}

            <DataCacheSocketComponent />

        </Fragment>
    )
}
