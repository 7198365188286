import { functions, UI, UIConfig } from "@newageerp/nae-react-ui";
import React from "react";
import { NaeSSchema } from '../../config/NaeSSchema';
import { useHistory } from "react-router";
import { OpenApi } from '@newageerp/nae-react-auth-wrapper';
import { getDepenciesForField } from "../../config/fields/fieldDependencies";
import { NaeTabs } from "../../config/tabs";
import { INaeEditSettings, INaePdf, INaeProperty, INaeSchema, INaeStatus, INaeTab, INaeViewSettings, INaeWidget } from '@newageerp/nae-react-ui/dist/interfaces';
import { NaeSProperties } from '../../config/NaeSProperties';
import { NaeSDbKeys } from "../../config/NaeSDbKeys";
import { NaeSStatuses } from "../../config/NaeSStatuses";
import { NaeViewFields } from "../../config/fields/view";
import { NaeEditFields } from "../../config/fields/edit";
import { NaeSPdfs } from '../../config/NaeSPdfs';
import { useRecoilValue } from "recoil";
import { NaeWidgets } from '../../config/widgets/index';
import { NaeSPropertiesKeys } from "../../config/NaeSPropertiesKeys";
import { NaeSSchemaFilterKeys, NaeSSchemaGroupKeys } from '../../config/NaeGroupFields';
import { IUIBuilderTabItem } from "@newageerp/nae-react-ui/dist/UIBuilderPages/UIBuilderTabsPage";
import { IUIBuilderWidgetRecordItem } from "@newageerp/nae-react-ui/dist/UIBuilderPages/UIBuilderWidgetsPage";
import { getHookForSchema } from "../../UserComponents/ModelsCacheData/ModelFields";
import { useDataCache } from '../Hooks/DataCacheProvider';

interface Props {
    children: any;
}

export default function InitComponent(props: Props) {
    const userState: any = useRecoilValue(OpenApi.naeUserState);
    console.log('userState', userState);
    const defaults = UI.UIBuilder.useUIBuilder();

    UIConfig.clientLogo =
    "https://blessify.com/wp-content/uploads/2021/07/blessify_logo_1.svg";

    const history = useHistory();

    UIConfig.getHookForSchema = getHookForSchema;

    UIConfig.fieldVisibility = () => ({});

    UIConfig.getFilterKeysForSchema = (schema: string) => {
        return NaeSSchemaFilterKeys[schema];
    }
    UIConfig.getGroupKeysForSchema = (schema: string) => {
        return NaeSSchemaGroupKeys[schema];
    }

    UIConfig.userState = userState;
    UIConfig.router = (props) => {
        history.push(props.path, props.options);
    };
    UIConfig.useURequest = OpenApi.useURequest;
    UIConfig.useUList = OpenApi.useUList;
    UIConfig.useUSave = OpenApi.useUSave;
    UIConfig.useURemove = OpenApi.useURemove;
    UIConfig.onEditElementUpdate = (schema: string, key: string, val: any, element: any) => {

        return element;
    }
    UIConfig.getDepenciesForField = (schema: string, key: string): string[] => {
        return getDepenciesForField(schema, key);
    }

    UIConfig.tabs = (): INaeTab[] => {
        return NaeTabs.concat(
            defaults.tabs.map((t: IUIBuilderTabItem) =>
                functions.config.configTabToInaeTab(t, defaults.defaults)
            )
        );
    };
    UIConfig.properties = (): INaeProperty[] => {
        return NaeSProperties;
    };
    UIConfig.schemas = (): INaeSchema[] => {
        return NaeSSchema;
    };
    UIConfig.statuses = (): INaeStatus[] => {
        return NaeSStatuses;
    };

    UIConfig.editFields = (): INaeEditSettings[] => {
        return NaeEditFields.concat(defaults.edit.map(e => functions.config.configEditToINaeEditSettings(e)));
    };
    UIConfig.viewFields = (): INaeViewSettings[] => {
        return NaeViewFields.concat(defaults.view.map(e => functions.config.configViewToINaeViewSettings(e)));;
    };
    UIConfig.pdfs = (): INaePdf[] => {
        return NaeSPdfs;
    };
    UIConfig.widgets = (): INaeWidget[] => {
        return NaeWidgets.concat(
            defaults.widgets.map((w: IUIBuilderWidgetRecordItem) =>
                functions.config.configWidgetToINaeWidget(w)
            )
        );
    };

    UIConfig.toast = OpenApi.toast;

    // @ts-ignore
    window.UIConfig = UIConfig;

    return props.children;
}
