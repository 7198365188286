import { INaeSchema } from "@newageerp/nae-react-ui/dist/interfaces";
export const NaeSSchema: INaeSchema[] = [
    {
        "className": "AbonddedCartEmail",
        "schema": "abondded-cart-email",
        "title": "",
        "titlePlural": ""
    },
    {
        "className": "AppContent",
        "schema": "app-content",
        "title": "Deprecated content",
        "titlePlural": "Deprecated content"
    },
    {
        "className": "AppContentArticle",
        "schema": "app-content-article",
        "title": "Article",
        "titlePlural": "Articles"
    },
    {
        "className": "AppContentBibleChapter",
        "schema": "app-content-bible-chapter",
        "title": "Bible chapter",
        "titlePlural": "Bible chapter"
    },
    {
        "className": "AppContentBibleTestament",
        "schema": "app-content-bible-testament",
        "title": "Bible testament",
        "titlePlural": "Bible testament"
    },
    {
        "className": "AppContentBibleVerse",
        "schema": "app-content-bible-verse",
        "title": "Bible verses",
        "titlePlural": "Bible verses"
    },
    {
        "className": "AppContentCategory",
        "schema": "app-content-category",
        "title": "Category",
        "titlePlural": "Categories"
    },
    {
        "className": "AppContentPray",
        "schema": "app-content-pray",
        "title": "Pray content",
        "titlePlural": "Pray content"
    },
    {
        "className": "AppContentPrayCategory",
        "schema": "app-content-pray-category",
        "title": "Pray category",
        "titlePlural": "Pray categories"
    },
    {
        "className": "AppContentQuote",
        "schema": "app-content-quote",
        "title": "Quote content",
        "titlePlural": "Quote content"
    },
    {
        "className": "AppContentSound",
        "schema": "app-content-sound",
        "title": "Sound content",
        "titlePlural": "Sound content"
    },
    {
        "className": "AppContentTopic",
        "schema": "app-content-topic",
        "title": "Topic",
        "titlePlural": "Topics"
    },
    {
        "className": "AppContentUserPrayer",
        "schema": "app-content-user-prayer",
        "title": "User prayers",
        "titlePlural": "User prayer"
    },
    {
        "className": "AppContentUserPrayerComment",
        "schema": "app-content-user-prayer-comment",
        "title": "Comment",
        "titlePlural": "Comments"
    },
    {
        "className": "AppContentUserPrayerPray",
        "schema": "app-content-user-prayer-pray",
        "title": "",
        "titlePlural": ""
    },
    {
        "className": "AppContentVerse",
        "schema": "app-content-verse",
        "title": "Verse content",
        "titlePlural": "Verse content"
    },
    {
        "className": "AppUser",
        "schema": "app-user",
        "title": "App user",
        "titlePlural": "App users"
    },
    {
        "className": "AppUserDevice",
        "schema": "app-user-device",
        "title": "User device",
        "titlePlural": "User devices"
    },
    {
        "className": "Bookmark",
        "schema": "bookmark",
        "title": "",
        "titlePlural": ""
    },
    {
        "className": "Subscription",
        "schema": "subscription",
        "title": "",
        "titlePlural": ""
    },
    {
        "className": "User",
        "schema": "user",
        "title": "User",
        "titlePlural": "Users"
    },
    {
        "className": "UserAuthSignInLog",
        "schema": "user-auth-sign-in-log",
        "title": "Auth log",
        "titlePlural": "Auth log"
    }
]
        export const NaeSSchemaMap = {
    "AbonddedCartEmail": {
        "className": "AbonddedCartEmail",
        "schema": "abondded-cart-email"
    },
    "AppContent": {
        "className": "AppContent",
        "schema": "app-content"
    },
    "AppContentBibleChapter": {
        "className": "AppContentBibleChapter",
        "schema": "app-content-bible-chapter"
    },
    "AppContentBibleTestament": {
        "className": "AppContentBibleTestament",
        "schema": "app-content-bible-testament"
    },
    "AppContentBibleVerse": {
        "className": "AppContentBibleVerse",
        "schema": "app-content-bible-verse"
    },
    "AppContentCategory": {
        "className": "AppContentCategory",
        "schema": "app-content-category"
    },
    "AppContentPray": {
        "className": "AppContentPray",
        "schema": "app-content-pray"
    },
    "AppContentQuote": {
        "className": "AppContentQuote",
        "schema": "app-content-quote"
    },
    "AppContentSound": {
        "className": "AppContentSound",
        "schema": "app-content-sound"
    },
    "AppContentUserPrayer": {
        "className": "AppContentUserPrayer",
        "schema": "app-content-user-prayer"
    },
    "AppContentVerse": {
        "className": "AppContentVerse",
        "schema": "app-content-verse"
    },
    "AppUser": {
        "className": "AppUser",
        "schema": "app-user"
    },
    "AppUserDevice": {
        "className": "AppUserDevice",
        "schema": "app-user-device"
    },
    "Subscription": {
        "className": "Subscription",
        "schema": "subscription"
    },
    "User": {
        "className": "User",
        "schema": "user"
    },
    "AppContentUserPrayerComment": {
        "className": "AppContentUserPrayerComment",
        "schema": "app-content-user-prayer-comment"
    },
    "UserAuthSignInLog": {
        "className": "UserAuthSignInLog",
        "schema": "user-auth-sign-in-log"
    },
    "AppContentTopic": {
        "className": "AppContentTopic",
        "schema": "app-content-topic"
    },
    "Bookmark": {
        "className": "Bookmark",
        "schema": "bookmark"
    },
    "AppContentArticle": {
        "className": "AppContentArticle",
        "schema": "app-content-article"
    },
    "AppContentUserPrayerPray": {
        "className": "AppContentUserPrayerPray",
        "schema": "app-content-user-prayer-pray"
    },
    "AppContentPrayCategory": {
        "className": "AppContentPrayCategory",
        "schema": "app-content-pray-category"
    }
}