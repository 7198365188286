import { INaeEditSettings } from '@newageerp/nae-react-ui/dist/interfaces';
import { NaeSSchemaMap } from '../../NaeSSchema';
import { NaeSPropertiesKeys } from '../../NaeSPropertiesKeys';
export const AppUserEditFields: INaeEditSettings[] = [
    {
        type: "main",
        schema: NaeSSchemaMap.AppUser.schema,
        fields: [
          [{ key: NaeSPropertiesKeys["app-user"].name }],
        //   [{ key: NaeSPropertiesKeys["app-user"].email }],
          [{ key: NaeSPropertiesKeys["app-user"].imageUrl }],
        ],
      },
];
