import { INaeViewSettings } from '@newageerp/nae-react-ui/dist/interfaces';
import { schemaMap } from '../../schema/schema';
import { propertiesKeys } from '../../properties/propertiesKeys';
export const UserViewFields: INaeViewSettings[] = [
    {
        type: "main",
        schema: schemaMap.User.schema,
        fields: [
          [{ key: propertiesKeys["user"].email }],
        ],
      },
];
