import { INaeTab } from "@newageerp/nae-react-ui/dist/interfaces";
import { schemaMap } from "../../schema/schema";
import { propertiesKeys } from "../../properties/propertiesKeys";
import { NaeSPropertiesKeys } from '../../NaeSPropertiesKeys';
export const AppUserTabs: INaeTab[] = [
  {
    quickSearchFilterKeys: ["i.name", "i.email", {key: "i.devices.deviceType", directSelect: false}],
    fields: [
      { key: propertiesKeys["app-user"].createdAt, link: true },
      { key: propertiesKeys["app-user"].name },
      { key: propertiesKeys["app-user"].email },
      { key: NaeSPropertiesKeys["app-user"].devicesTypes },
    ],
    sort: [
      {
        key: "i.id",
        value: "ASC",
      },
    ],
    schema: schemaMap.AppUser.schema,
    type: "main",
    exports: [
      {
        schema: schemaMap.AppUser.schema,
        title: "Export",
        type: "main",
        fields: [
          { key: propertiesKeys["app-user"].createdAt},
          { key: propertiesKeys["app-user"].name },
          { key: propertiesKeys["app-user"].email },
          { key: NaeSPropertiesKeys["app-user"].devicesTypes },
        ],
      }
    ]
  },
];
