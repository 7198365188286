import { INaeViewSettings } from "@newageerp/nae-react-ui/dist/interfaces";
import { schemaMap } from "../../schema/schema";
import { NaeSPropertiesKeys } from '../../NaeSPropertiesKeys';
export const AppContentUserPrayerViewFields: INaeViewSettings[] = [
  {
    schema: schemaMap.AppContentUserPrayer.schema,
    type: "main",
    fields: [
        [
            {
                key: NaeSPropertiesKeys['app-content-user-prayer'].name
            }
        ],
        [
            {
                key: NaeSPropertiesKeys['app-content-user-prayer'].appUser,
                relKey: 'email'
            }
        ],
        [
            {
                key: NaeSPropertiesKeys['app-content-user-prayer'].prayer
            }
        ],
        [
            {
                key: NaeSPropertiesKeys['app-content-user-prayer'].createdAt
            }
        ],
        [
            {
                key: NaeSPropertiesKeys['app-content-user-prayer'].moderated
            }
        ],
        [
            {
                key: NaeSPropertiesKeys['app-content-user-prayer'].praysCount
            }
        ],
        [
            {
                key: NaeSPropertiesKeys['app-content-user-prayer'].commentsCount
            }
        ]
    ],
  },
];
