import { INaeEditSettings } from "@newageerp/nae-react-ui/dist/interfaces";
import { schemaMap } from "../../schema/schema";
import { propertiesKeys } from "../../properties/propertiesKeys";

export const UserEditFields: INaeEditSettings[] = [
  {
    type: "main",
    schema: schemaMap.User.schema,
    fields: [
      [{ key: propertiesKeys["user"].email }],
      [{ key: propertiesKeys["user"].plainPassword }],
    ],
  },
];
