import React, { Fragment } from "react";
import { INaeWidget } from "@newageerp/nae-react-ui/dist/interfaces";
import { AbonddedCartEmailWidgets } from "./widgets/abondded-cart-email.widgets";
import { AppContentWidgets } from "./widgets/app-content.widgets";
import { AppContentBibleChapterWidgets } from "./widgets/app-content-bible-chapter.widgets";
import { AppContentBibleTestamentWidgets } from "./widgets/app-content-bible-testament.widgets";
import { AppContentBibleVerseWidgets } from "./widgets/app-content-bible-verse.widgets";
import { AppContentCategoryWidgets } from "./widgets/app-content-category.widgets";
import { AppContentPrayWidgets } from "./widgets/app-content-pray.widgets";
import { AppContentQuoteWidgets } from "./widgets/app-content-quote.widgets";
import { AppContentSoundWidgets } from "./widgets/app-content-sound.widgets";
import { AppContentUserPrayerWidgets } from "./widgets/app-content-user-prayer.widgets";
import { AppContentUserPrayerCommentWidgets } from "./widgets/app-content-user-prayer-comment.widgets";
import { AppContentVerseWidgets } from "./widgets/app-content-verse.widgets";
import { AppUserWidgets } from "./widgets/app-user.widgets";
import { AppUserDeviceWidgets } from "./widgets/app-user-device.widgets";
import { SubscriptionWidgets } from "./widgets/subscription.widgets";
import { UserWidgets } from "./widgets/user.widgets";
import { UserAuthSignInLogWidgets } from "./widgets/user-auth-sign-in-log.widgets";
import { AppContentArticleWidgets } from "./widgets/app-content-article.widgets";

export const NaeWidgets: INaeWidget[] = [
    ...AbonddedCartEmailWidgets,
    ...AppContentWidgets,
    ...AppContentBibleChapterWidgets,
    ...AppContentBibleTestamentWidgets,
    ...AppContentBibleVerseWidgets,
    ...AppContentCategoryWidgets,
    ...AppContentPrayWidgets,
    ...AppContentQuoteWidgets,
    ...AppContentSoundWidgets,
    ...AppContentUserPrayerWidgets,
    ...AppContentUserPrayerCommentWidgets,
    ...AppContentVerseWidgets,
    ...AppUserWidgets,
    ...AppUserDeviceWidgets,
    ...SubscriptionWidgets,
    ...UserWidgets,
    ...UserAuthSignInLogWidgets,
    ...AppContentArticleWidgets,

];
