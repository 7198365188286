import { INaeEditSettings } from "@newageerp/nae-react-ui/dist/interfaces";
import { AbonddedCartEmailEditFields } from "./edit/abondded-cart-email.edit.fields";
import { AppContentEditFields } from "./edit/app-content.edit.fields";
import { AppContentBibleChapterEditFields } from "./edit/app-content-bible-chapter.edit.fields";
import { AppContentBibleTestamentEditFields } from "./edit/app-content-bible-testament.edit.fields";
import { AppContentBibleVerseEditFields } from "./edit/app-content-bible-verse.edit.fields";
import { AppContentCategoryEditFields } from "./edit/app-content-category.edit.fields";
import { AppContentPrayEditFields } from "./edit/app-content-pray.edit.fields";
import { AppContentQuoteEditFields } from "./edit/app-content-quote.edit.fields";
import { AppContentSoundEditFields } from "./edit/app-content-sound.edit.fields";
import { AppContentUserPrayerEditFields } from "./edit/app-content-user-prayer.edit.fields";
import { AppContentUserPrayerCommentEditFields } from "./edit/app-content-user-prayer-comment.edit.fields";
import { AppContentVerseEditFields } from "./edit/app-content-verse.edit.fields";
import { AppUserEditFields } from "./edit/app-user.edit.fields";
import { AppUserDeviceEditFields } from "./edit/app-user-device.edit.fields";
import { SubscriptionEditFields } from "./edit/subscription.edit.fields";
import { UserEditFields } from "./edit/user.edit.fields";
import { UserAuthSignInLogEditFields } from "./edit/user-auth-sign-in-log.edit.fields";

export const NaeEditFields: INaeEditSettings[] = [
  ...AbonddedCartEmailEditFields,
...AppContentEditFields,
...AppContentBibleChapterEditFields,
...AppContentBibleTestamentEditFields,
...AppContentBibleVerseEditFields,
...AppContentCategoryEditFields,
...AppContentPrayEditFields,
...AppContentQuoteEditFields,
...AppContentSoundEditFields,
...AppContentUserPrayerEditFields,
...AppContentUserPrayerCommentEditFields,
...AppContentVerseEditFields,
...AppUserEditFields,
...AppUserDeviceEditFields,
...SubscriptionEditFields,
...UserEditFields,
...UserAuthSignInLogEditFields,

];