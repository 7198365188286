import { INaeTab } from "@newageerp/nae-react-ui/dist/interfaces";
import { propertiesKeys } from "../../properties/propertiesKeys";
import { schemaMap } from "../../schema/schema";
export const AppContentSoundTabs: INaeTab[] = [
  {
    quickSearchFilterKeys: ['i.title', 'i.url', 'i.audio'],
    fields: [
      { key: propertiesKeys["app-content-sound"].title, link: true },
      { key: propertiesKeys["app-content-sound"].subTitle },
      { key: propertiesKeys["app-content-sound"].duration },
      { key: propertiesKeys["app-content-sound"].url },
      { key: propertiesKeys["app-content-sound"].audio },
    ],
    sort: [
      {
        key: "i.id",
        value: "ASC",
      },
    ],
    schema: schemaMap.AppContentSound.schema,
    type: "main",
  },
];
