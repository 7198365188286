import { useEffect } from "react";
import { NaeSSchemaMap } from "../../config/NaeSSchema";
import { useDataCache } from "./DataCacheProvider";
import { useSelector } from "react-redux";
import { SelectorAppContentTopicNae } from "../Models/ormSelectors";

interface ChildId {
  id: number
}

export interface IAppContentTopicModelNae {
    id: number,
name: string,

}

export const IAppContentTopicFieldsNae = ["id","name"];

export function useAppContentTopicHookNae(id: number) : IAppContentTopicModelNae {
  // @ts-ignore
  const element = useSelector((state) => SelectorAppContentTopicNae(state, id));

  const { addToQueue } = useDataCache();

  useEffect(() => {
    if (!element) {
      addToQueue({
        elementId: id,
        schema: NaeSSchemaMap.AppContentTopic.schema,
        fields: IAppContentTopicFieldsNae,
      });
    }
  }, [element, id]);

  return element;
}
